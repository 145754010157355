import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';

import { setCurrentUser, deletePatient } from '../../../redux/actions/userActions';
import { fetchTimeSlots } from '../../../redux/actions/timeSlotsActions';
import CustomCard from '../../shared/CustomCard/CustomCard';
import UserIconButton from '../../shared/UserIconButton/UserIconButton';
import ConfirmDialog from '../../shared/ConfirmDialog/ConfirmDialog';
import styles from './PatientsCards.module.scss';

const PatientsCards = ( { cardsView, patients, history, location, t, ...props } ) => {

  const selectPatient = async ( patient ) => {
    await props.setCurrentUser( patient.id );
    await props.fetchTimeSlots( patient.id );
    history.push( '/agenda' );
  }

  const deleteIconAction = ( id ) => {
    return () => {
      props.deletePatient( id );
    }
  }

  const getAction = ( isSelected, patientId, patientName ) => {
    const deleteStrings = {
      cancel: t( 'cancel' ),
      confirm: t( 'confirm' ),
      title: t( 'delete modal title' ),
      description: t( 'delete modal description' )
    }
    return cardsView ?
      (
        isSelected ?
          <div className={ styles.currentText }>{ t( 'current' ) }</div>
          :
          <ArrowForwardIcon className={ styles.currentIcon }></ArrowForwardIcon>
      )
      :
      (
        isSelected ?
          <ArrowForwardIcon className={ styles.currentIcon }></ArrowForwardIcon>
          :
          (
            <ConfirmDialog confirmAction={ deleteIconAction( patientId ) } strings={ deleteStrings } name={ patientName }>
              <DeleteIcon className={ styles.currentIconDelete }></DeleteIcon>
            </ConfirmDialog>
          )
      )
  }

  const getPatientCard = ( patient ) => {
    const userId = localStorage.getItem( 'userId' ) || new URLSearchParams( location.search ).get( 'userId' ),
      isSelected = patient.id === userId,
      patientTitle = `${patient.data.firstName} ${patient.data.lastName}`,
      patientProps = {
        onClick: () => ( cardsView || isSelected ) ? selectPatient( patient ) : () => { },
        key: patient.id,
        icon: ( <UserIconButton userData={ patient.data }></UserIconButton> ),
        title: patientTitle,
        content: `${patient.data.institution},`,
        content2: `${patient.data.department}, ${t( 'chamber' )} ${patient.data.chamberNumber}`,
        selected: isSelected,
        clickable: cardsView || isSelected,
        action: getAction( isSelected, patient.id, patientTitle )
      };
    return ( <CustomCard { ...patientProps }></CustomCard> )
  }

  const getPatientsCards = () => {
    const patientsCards = [];
    for ( const patient in patients ) {
      patientsCards.push( getPatientCard( patients[ patient ] ) );
    }
    return patientsCards;
  }


  return (
    <div className={ styles.PatientsCards }>
      { getPatientsCards() }
    </div>
  )
};

const patientsCardsActions = ( dispatch ) => {
  return {
    setCurrentUser: ( id ) => dispatch( setCurrentUser( id ) ),
    fetchTimeSlots: ( id ) => dispatch( fetchTimeSlots( id ) ),
    deletePatient: ( id ) => dispatch( deletePatient( id ) )
  }
}

export default connect( null, patientsCardsActions )( withRouter( withNamespaces( "PatientsCards" )( PatientsCards ) ) );
