import React, { ReactElement } from 'react';
import styles from './CustomButton.module.scss';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import MailIcon from '@material-ui/icons/Mail';
import { Button, withStyles, ButtonProps } from '@material-ui/core';

const CustomCssButton = withStyles({
  root: {
    'text-transform': 'none',
    'border-radius': '10px',
    'padding': '10px',
    'margin': '18.5px 0',
    'font-family': 'Geomanist Medium',
    'font-weight': '500',
    'font-size': '18px',
    'line-height': '18px'
  },
})(Button);

const CustomSecondaryCssButton = withStyles({
  root: {
    'text-transform': 'none',
    'border-radius': '10px',
    'padding': '10px',
    'margin': '18.5px 0',
    'font-size': '16px',
    'font-family': 'Geomanist Book',
    'font-weight': 'normal',
    'line-height': '16px'
  },
})(Button);

interface aditionalProps {
  iconType?: "check" | "sum" | "forward-arrow" | "delete" | "invite"
}

const CustomButton: React.SFC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement> & aditionalProps> = ({ children, className = "", iconType, ...buttonProps }) => {
  let htmlIcon: ReactElement = (<React.Fragment></React.Fragment>);
  if (buttonProps.color === "secondary") {
    className = styles.secondaryButton;
  }
  if (iconType) {
    switch (iconType) {
      case "check":
        htmlIcon = (<CheckIcon></CheckIcon>);
        break;
      case "sum":
        htmlIcon = (<AddIcon></AddIcon>);
        break
      case "forward-arrow":
        htmlIcon = (<ArrowForwardIcon></ArrowForwardIcon>)
        break;
      case "delete":
        htmlIcon = (<DeleteIcon></DeleteIcon>)
        break;
      case "invite":
        htmlIcon = (<MailIcon></MailIcon>)
        break;
      default:
        htmlIcon = (<React.Fragment></React.Fragment>);
    }

  }
  return buttonProps.color !== "secondary" ? (
    <CustomCssButton className={className} variant="contained" color="primary" fullWidth {...buttonProps}>
      <div className={styles.child}>{children}</div>
      <div className={styles.icon}>{htmlIcon}</div>
    </CustomCssButton>
  ) :
    (
      <CustomSecondaryCssButton className={className} variant="contained" fullWidth {...buttonProps}>
        <div className={styles.child}>{children}</div>
        <div className={styles.icon}>{htmlIcon}</div>
      </CustomSecondaryCssButton>

    )
};

export default CustomButton;
