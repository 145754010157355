import styles from './SignIn.module.scss';
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';

import CustomInputField from '../shared/InputField/CustomInputField';
import UnauthorisedLayout from '../shared/UnauthorisedLayout/UnauthorisedLayout';
import CustomButton from '../shared/CustomButton/CustomButton';
import LinkButton from '../shared/LinkButton/LinkButton';
import { signIn, resetPasswordReseted } from '../../redux/actions/authActions';
import CustomSnackbar, { severities } from '../shared/CustomSnackbar/CustomSnackbar';

const SignIn = ( { t, signIn, history, resetPasswordReseted, auth } ) => {
  const [ email, setEmail ] = useState( "" );
  const [ password, setPassword ] = useState( "" );
  const [ error, setErrors ] = useState( "" );

  const handleForm = ( event ) => {
    event.preventDefault();
    signIn( email, password ).then( ( res ) => {
      setErrors( "" );
      history.push( '/agenda' )
    } ).catch( error => {
      console.log( error );
      setErrors( `${error.message}` );
    } );
  };

  return (
    <UnauthorisedLayout error={ error } setError={ setErrors } pageHeader={
      <div className={ styles.pageTitle }>
        <h1>{ t( 'page title' ) }</h1>
        <LinkButton className={ styles.mobileButton } to="/sign-up" buttonProps={ { variant: "contained", color: "secondary" } }>
          <div className={ styles.signUpButton }>{ t( 'sign up' ) }</div>
        </LinkButton>
      </div>
    }>
      <div className={ styles.SignIn }>
        <form className={ styles.SignInForm } onSubmit={ event => handleForm( event ) }>
          <CustomInputField
            value={ email }
            onChange={ e => setEmail( e.target.value ) }
            name="email"
            type="email"
            label={ t( 'email' ) }
            required
          />
          <CustomInputField
            onChange={ e => setPassword( e.target.value ) }
            name="password"
            value={ password }
            type="password"
            label={ t( 'password' ) }
            required
          />
          <CustomButton variant="contained" color="primary" type="submit">{ t( 'login' ) }</CustomButton>
        </form>
        <LinkButton className={ styles.desktopButton } to="/sign-up" buttonProps={ { variant: "contained", color: "secondary" } }>
          <div className={ styles.signUpButton }>{ t( 'sign up' ) }</div>
        </LinkButton>
        <div className={ styles.ForgotPasword }>
          <div>{ t( 'forgot password' ) }</div>
          <Link className={ styles.resetPasswordLink } to="/password-reset">{ t( 'reset password' ) }</Link>
        </div>
      </div>
      {
        auth.passwordReseted &&
        <CustomSnackbar severity={ severities.success } closeSnackBar={ () => resetPasswordReseted() }>{ t( 'password reset sent' ) }</CustomSnackbar>
      }
    </UnauthorisedLayout>
  );

};

const mapStateToProps = ( state ) => {
  return {
    auth: state.auth
  }
}

const signInAction = ( dispatch ) => {
  return {
    signIn: ( email, password ) => dispatch( signIn( email, password ) ),
    resetPasswordReseted: () => dispatch( resetPasswordReseted() )
  }
}

export default connect( mapStateToProps, signInAction )( withRouter( withNamespaces( "SignIn" )( SignIn ) ) );