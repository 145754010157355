import React, { useState } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import clsx from 'clsx';
import Select from 'react-select';
import styles from './UnauthorisedLayout.module.scss';
import CustomSnackbar from '../CustomSnackbar/CustomSnackbar';
import VTPImage from '../../../assets/images/vtpLogo.png';
import vtpVisitImgDesktop from '../../../assets/images/vtpVisitDesktop.png';
import vtpVisitImgMobile from '../../../assets/images/vtpVisitMobile.png';
import enFlag from '../../../assets/icons/countries/UK-flag-icon-64.png';
import deFlag from '../../../assets/icons/countries/DE-flag-icon-64.png';
import geFlag from '../../../assets/icons/countries/GE-flag-icon-64.png';
import Footer from '../Footer/Footer';

interface UnauthorisedLayoutProps {
  children?: React.ReactNode;
  pageHeader?: React.ReactNode;
  error?: string;
  setError?: any;
  fullMobileTitle?: boolean,
  showMobileImg?: boolean,
  chooseAccount?: boolean
}

const UnauthorisedLayout: React.SFC<WithNamespaces & UnauthorisedLayoutProps> = ({ children, i18n, error, setError, pageHeader, fullMobileTitle, chooseAccount, showMobileImg = true }) => {

  const options = [
    { value: "en", label: <div><img className={styles.flag} src={enFlag} alt="" />English</div> },
    { value: "ge", label: <div><img className={styles.flag} src={geFlag} alt="" />German</div> },
    { value: "de", label: <div><img className={styles.flag} src={deFlag} alt="" />Dutch</div> }
  ]

  const [currentValue, changeCurrentValue] = useState(options.find(opt => opt.value === i18n.languages[0].slice(0, 2)) || options[0]);

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng.value);
    changeCurrentValue(lng);
  };

  return (
    <div>
      <div className={clsx(styles.UnauthorisedLayout)}>
        <div className={styles.desktopHeader}>
          <img className={styles.desktopImg} src={VTPImage} alt="" />
          <Select
            className={styles.desktopLanguageSelector}
            classNamePrefix="select"
            defaultValue={currentValue}
            value={currentValue}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            name="language-selector"
            options={options}
            onChange={(val) => { changeLanguage(val) }}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.leftContainer}>
            {<div className={clsx(fullMobileTitle ? styles.pageFullMobileTitle : styles.pageTitle)}>
              <Select
                className={styles.mobileLanguageSelector}
                classNamePrefix="select"
                defaultValue={currentValue}
                value={currentValue}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                name="language-selector"
                options={options}
                onChange={(val) => { changeLanguage(val) }}
              />
              {pageHeader}
            </div>
            }
            {showMobileImg &&
              <div className={styles.mobileImageContainer}>
                <img className={styles.mobileImg} src={vtpVisitImgMobile} alt="" />
              </div>
            }
            {children}
          </div>
          <div className={clsx(styles.rightContainer, chooseAccount && styles.rightContainerChoose)}>
            <img src={vtpVisitImgDesktop} alt="" />
          </div>
        </div>
      </div>

      <Footer></Footer>

      <CustomSnackbar severity={"error"} closeSnackBar={() => setError("")} >{error}</CustomSnackbar>
    </div>
  )
};

export default withNamespaces()(UnauthorisedLayout);
