import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import CustomButton from '../../shared/CustomButton/CustomButton';
import CustomInputField from '../../shared/InputField/CustomInputField';
import { addDiaryNote, editDiaryNote } from "../../../redux/actions/diaryNotes";

import styles from './DiaryBookAdd.module.scss';

const DiaryBookAdd = ( { addDiaryNote, editDiaryNote, diaryNotes, location, history, currentUser, t, i18n } ) => {
  const [ noteTitle, setNoteTitle ] = useState( "" );
  const [ noteText, setNoteText ] = useState( "" );

  const id = new URLSearchParams( location.search ).get( 'id' );
  let isNew = true, diaryNote = { id: "", data: { title: "", text: "" } }, title, text;

  if ( id && diaryNotes.diaryNotes.length ) {
    diaryNote = diaryNotes.diaryNotes.find( diaryNote => diaryNote.id === id );
    title = diaryNote.data.title;
    text = diaryNote.data.text;
    isNew = false;
  }

  useEffect( () => {
    title && setNoteTitle( title );
    text && setNoteText( text )
  }, [ title, text ] );

  const saveDiaryNote = () => {
    const userId = localStorage.getItem( 'userId' );
    diaryNote.data.title = noteTitle;
    diaryNote.data.text = noteText;
    diaryNote.data.date = diaryNote.data.date || ( new Date() ).toUTCString();
    isNew ?
      addDiaryNote( { ...diaryNote.data }, userId, `${currentUser.firstName} ${currentUser.lastName}`, i18n.languages[ 0 ].slice(0,2) )
      :
      editDiaryNote( diaryNote, userId )

    history.push( '/diary-book' )
  }

  return (
    <React.Fragment>
      {
        !diaryNotes.isLoading &&
        ( <form className={ styles.DiaryBookAdd }>

          <h1>{ isNew ? t( 'title add' ) : t( 'title edit' ) }</h1>
          <CustomInputField placeholder={ t( 'note title placeholder' ) }
            value={ noteTitle } onChange={ ( e ) => setNoteTitle( e.target.value ) }
          ></CustomInputField>

          <CustomInputField placeholder={ t( 'note text placeholder' ) } value={ noteText }
            onChange={ ( e ) => setNoteText( e.target.value ) } textArea
          ></CustomInputField>

          <CustomButton onClick={ saveDiaryNote } iconType="check">{ t( 'save' ) }</CustomButton>
        </form>
        )
      }
    </React.Fragment>
  )
};

const mapStateToProps = ( state ) => {
  return {
    diaryNotes: state.diaryNotes,
    currentUser: state.auth.currentUserData
  }
}

const addNewDiaryNoteActions = ( dispatch ) => {
  return {
    addDiaryNote: ( DiaryNoteData, id, userName, lng ) => dispatch( addDiaryNote( DiaryNoteData, id, userName, lng ) ),
    editDiaryNote: ( DiaryNoteData, id ) => dispatch( editDiaryNote( DiaryNoteData, id ) ),
    // deleteDiaryNote: ( DiaryNoteId, id ) => dispatch( deleteDiaryNote( DiaryNoteId, id ) )
  }
}

export default connect( mapStateToProps, addNewDiaryNoteActions )( withRouter( withNamespaces( "AddDiaryBook" )( DiaryBookAdd ) ) );
