import React, { useState } from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { resetPassword } from '../../redux/actions/authActions';
import styles from './PasswordReset.module.scss';
import UnauthorisedLayout from '../shared/UnauthorisedLayout/UnauthorisedLayout';
import CustomInputField from '../shared/InputField/CustomInputField';
import CustomButton from '../shared/CustomButton/CustomButton';




const PasswordReset = ( { t, ...props } ) => {

  const [ email, setEmail ] = useState( "" );

  const onClick = () => {
    props.resetPassword( email ).then( ( res ) => {
      // Email sent.
      props.history.push('/login')
    } ).catch( function ( error ) {
      console.log( `err: ${error}` );
      // An error happened.
    } );
  }
  return (
    <UnauthorisedLayout pageHeader={ <h1>{ t( 'title' ) }</h1> }>
      <div className={ styles.PasswordReset }>
        <span className={ styles.text }>{ t( 'text' ) }</span>
        <CustomInputField type="email" value={ email } onChange={ ( e ) => setEmail( e.target.value ) } label={ t( 'placeholder' ) } />
        <div className={ styles.buttonContainer }><CustomButton onClick={ onClick }>{ t( 'button text' ) }</CustomButton></div>
      </div>
    </UnauthorisedLayout>
  )
};


const resetPasswordAction = ( dispatch ) => {
  return {
    resetPassword: ( email ) => dispatch( resetPassword( email ) )
  }
}

// export default connect( null, addUserAction )( withRouter( SignUp ) );

export default connect( null, resetPasswordAction )( withRouter( withNamespaces( "PasswordReset" )( PasswordReset ) ) );
