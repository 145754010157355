import React from 'react';
import styles from './CustomSnackbar.module.scss';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';

interface CustomSnackbarI {
  children: string | undefined,
  closeSnackBar?: any
}


const CustomSnackbar: React.SFC<CustomSnackbarI & AlertProps> = ({ children, closeSnackBar, ...alertProps }) => {
  return (
    <Snackbar className={styles.CustomSnackbar} open={!!children} autoHideDuration={5000} onClose={closeSnackBar}>
      <Alert className={styles.Alert} onClose={closeSnackBar} {...alertProps}>
        {children}
      </Alert>
    </Snackbar>
  )
};

export default CustomSnackbar;

export const severities = { error: "error", warning: "warning", info: "info", success: "success" };
