import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';

import { editUserDetails } from '../../../redux/actions/userActions';
import CustomButton from '../../shared/CustomButton/CustomButton';
import CustomInputField from '../../shared/InputField/CustomInputField';
import AccountImg from '../../../assets/images/Account.png';

import styles from './EditUserDetails.module.scss';

const EditUserDetails = ( { currentUser, t, ...props } ) => {
  const handleForm = ( event ) => {
    event.preventDefault();
    const userDetails = { institution, department, chamberNumber },
      userId = isNew ? "" : localStorage.getItem( 'userId' );
    props.editUserDetails( userDetails, userId ).then( ( res ) => {
      isNew ? props.history.push( '/agenda' ) : props.history.push( '/profile' );
    } ).catch( error => {
      console.log( error );
      // setErrors( `${error.message}` );
    } );
  };

  const [ institution, setInstitution ] = useState( "" );
  const [ department, setDepartment ] = useState( "" );
  const [ chamberNumber, setChamberNumber ] = useState( "" );
  const [ isNew, setIsNew ] = useState( true );

  React.useEffect( () => {
    if ( currentUser && currentUser.institution ) {
      setInstitution( currentUser.institution );
      setDepartment( currentUser.department );
      setChamberNumber( currentUser.chamberNumber );
    }
    setIsNew( false );
  }, [ currentUser ] );


  return (
    <form className={ styles.EditUserDetails } onSubmit={ event => handleForm( event ) }>
      <h1>{ ( currentUser && currentUser.firstName ) ? t( "edit title" ) : t( "add title" ) }</h1>
      <img src={ AccountImg } alt="" />
      <CustomInputField
        value={ institution }
        onChange={ e => setInstitution( e.target.value ) }
        name="healthcare-institution"
        type="text"
        placeholder={ t( "heathcare institution" ) }
        required
      />
      <CustomInputField
        value={ department }
        onChange={ e => setDepartment( e.target.value ) }
        name="department"
        type="text"
        placeholder={ t( "department" ) }
        required
      />
      <CustomInputField
        value={ chamberNumber }
        onChange={ e => setChamberNumber( e.target.value ) }
        name="chamber-number"
        type="number"
        placeholder={ t( "chamber number" ) }
        required
      />

      <CustomButton iconType={ "check" } type="submit">{ t( "submit" ) }</CustomButton>
    </form>
  )
};

const mapStateToProps = ( state ) => {
  return {
    currentUser: state.auth.currentUserData
  }
}

const editUserDetailsAction = ( dispatch ) => {
  return {
    editUserDetails: ( userDetails, userId ) => dispatch( editUserDetails( userDetails, userId ) )
  }
}


export default connect( mapStateToProps, editUserDetailsAction )( withRouter( withNamespaces( "EditUser" )( EditUserDetails ) ) );
