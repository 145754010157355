import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme( {
  palette: {
    primary: {
      main: "#ED145B"
    },
    secondary: {
      main: "#F69579",
      mainGradient: "linear-gradient(168.77deg, #F69579 4.37%, #F47572 101.74%);"
    },
  },
  typography: {
    "fontFamily": `"Geomanist Book","Geomanist","Roboto", "Helvetica", "Arial", sans-serif`,
  }
} );

export default theme;