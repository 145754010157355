import { ADD_USER, LOG_IN, RESET_PASSWORD, SET_CURRENT_USER, FETCH_PATIENTS, LOADING, SET_PASSWORD_RESETED, DELETE_USER, START_DELETE, RESET_STATE } from "../actionTypes";
import * as actionTypes from "../actionTypes";

const initState = {
  isLoading: true,
  currentAuthState: "normal",
  passwordReseted: false,
  patients: [],
  currentUserData: {}
}

const authReducer = ( state = initState, action ) => {
  if ( action.type in actionTypes ) {
    console.log( action );
  }
  switch ( action.type ) {
    case ADD_USER:
      return {
        ...state,
        isLoading: false,
      };
    case `${ADD_USER}_ERROR`:
      return {
        ...state,
        isLoading: false,
      };
    case LOG_IN:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        isLoading: false,
        passwordReseted: true,
      };
    case SET_PASSWORD_RESETED:
      return {
        ...state,
        isLoading: false,
        passwordReseted: action.payload || false
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        // isLoading: false,
        currentUserData: action.payload
      };
    case FETCH_PATIENTS:
      return {
        ...state,
        isLoading: false,
        patients: action.payload
      }

    case LOADING:
      return {
        ...state
      };

    case START_DELETE:
      return {
        ...state,
        isLoading: false,
        currentAuthState: "deleting"

      }

    case DELETE_USER:
      return {
        ...initState,
        isLoading: false,
        currentAuthState: "deleted"
      };

    case RESET_STATE: 
      return initState

    default:
      return state;
  }
}

export default authReducer;