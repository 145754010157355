import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CustomInputField from '../../shared/InputField/CustomInputField';
import { Typography } from '@material-ui/core';
import CustomButton from '../../shared/CustomButton/CustomButton';
import styles from './Step1.module.scss';


const Step1 = ( { currentStep, firstName, lastName, email, password, acceptedTC, validate, setFormErrors, t } ) => {

  const [ errors, setErrors ] = useState( {} );

  const createAccount = () => {
    validate(
      ( err, value ) => {
        if ( !err ) {
          currentStep.handler( "2" );
        }
        else {
          const error = {};
          error[ err.details[ 0 ].path[ 0 ] ] = err.details[ 0 ];
          if ( error.acceptedTC ) {
            setFormErrors( error.acceptedTC.message )
          }
          setErrors( error );
        }
      }
    )
  }

  const onTxtChange = ( callback, value ) => {
    setErrors( "" );
    return callback( value );
  }
  if ( currentStep.value !== "1" ) {
    return null
  }

  return (
    <div className={ styles.Step1 }>
      <CustomInputField
        value={ firstName.value }
        onChange={ ( e ) => onTxtChange( firstName.handler, e.target.value ) }
        name="first-name"
        type="text"
        label={ t( "first name" ) }
        required
        errorMessage={ errors.firstName ? errors.firstName.message : "" }
      />
      <CustomInputField
        value={ lastName.value }
        onChange={ ( e ) => onTxtChange( lastName.handler, e.target.value ) }
        name="last-name"
        type="text"
        label={ t( "last name" ) }
        required
        errorMessage={ errors.lastName ? errors.lastName.message : "" }
      />
      <CustomInputField
        value={ email.value }
        onChange={ ( e ) => onTxtChange( email.handler, e.target.value ) }
        name="email"
        type="email"
        label={ t( "email" ) }
        required
        errorMessage={ errors.email ? errors.email.message : "" }
      />
      <CustomInputField
        onChange={ ( e ) => onTxtChange( password.handler, e.target.value ) }
        name="password"
        value={ password.value }
        type="password"
        label={ t( "password" ) }
        inputProps={{minLength : 6}}
        required
        errorMessage={ errors.password ? errors.password.message : "" }
      />
      <CustomButton onClick={ () => { createAccount() } }>{ t( "create account" ) }</CustomButton>
      <FormControlLabel
        className={ styles.FormControlLabel }
        control={ <Checkbox checked={ acceptedTC.value } onChange={ () => { setFormErrors( "" ); return acceptedTC.handler( !acceptedTC.value ) } } name="gilad" /> }
        label={ <Typography className={ styles.LabelTypography }>{ t( 'T&C' ) }</Typography> }
      />
    </div>
  )
};

export default withNamespaces("SignUpStep1")(Step1);
