import AgendaEN from "../constants/translations/Agenda/en/index.json";
import AgendaDE from "../constants/translations/Agenda/de/index.json";
import AgendaGE from "../constants/translations/Agenda/ge/index.json";
import AddTimeSlotEN from "../constants/translations/Agenda/en/addTimeSlot.json";
import AddTimeSlotDE from "../constants/translations/Agenda/de/addTimeSlot.json";
import AddTimeSlotGE from "../constants/translations/Agenda/ge/addTimeSlot.json";
import DeleteTimeSlotEN from "../constants/translations/Agenda/en/deleteTimeSlot.json";
import DeleteTimeSlotDE from "../constants/translations/Agenda/de/deleteTimeSlot.json";
import DeleteTimeSlotGE from "../constants/translations/Agenda/ge/deleteTimeSlot.json";
import TimeSlotCardsEN from "../constants/translations/Agenda/en/timeSlotCards.json";
import TimeSlotCardsDE from "../constants/translations/Agenda/de/timeSlotCards.json";
import TimeSlotCardsGE from "../constants/translations/Agenda/ge/timeSlotCards.json";

import CancelAppointmentEN from "../constants/translations/CancelAppointment/en";
import CancelAppointmentDE from "../constants/translations/CancelAppointment/de";
import CancelAppointmentGE from "../constants/translations/CancelAppointment/ge";

import ChooseTimeEN from "../constants/translations/ChooseTime/en/index.json";
import ChooseTimeDE from "../constants/translations/ChooseTime/de/index.json";
import ChooseTimeGE from "../constants/translations/ChooseTime/ge/index.json";
import FeedbackDialogEN from "../constants/translations/ChooseTime/en/FeedbackDialog.json"
import FeedbackDialogDE from "../constants/translations/ChooseTime/de/FeedbackDialog.json"
import FeedbackDialogGE from "../constants/translations/ChooseTime/ge/FeedbackDialog.json"

import CommonInputsEN from "../constants/translations/commons/en/inputs.json";
import CommonInputsDE from "../constants/translations/commons/de/inputs.json";
import CommonInputsGE from "../constants/translations/commons/ge/inputs.json";
import CommonActionsEN from "../constants/translations/commons/en/actions.json";
import CommonActionsDE from "../constants/translations/commons/de/actions.json";
import CommonActionsGE from "../constants/translations/commons/ge/actions.json";

import ConfirmMailEN from "../constants/translations/ConfirmMail/en/index.json";
import ConfirmMailDE from "../constants/translations/ConfirmMail/de/index.json";
import ConfirmMailGE from "../constants/translations/ConfirmMail/ge/index.json";

import DiaryBookEN from "../constants/translations/DiaryBook/en/index.json";
import DiaryBookDE from "../constants/translations/DiaryBook/de/index.json";
import DiaryBookGE from "../constants/translations/DiaryBook/ge/index.json";
import AddDiaryBookEN from "../constants/translations/DiaryBook/en/addDiaryBook.json";
import AddDiaryBookDE from "../constants/translations/DiaryBook/de/addDiaryBook.json";
import AddDiaryBookGE from "../constants/translations/DiaryBook/ge/addDiaryBook.json";

import EditUserEN from "../constants/translations/EditUser/en/index.json";
import EditUserDE from "../constants/translations/EditUser/de/index.json";
import EditUserGE from "../constants/translations/EditUser/ge/index.json";

import GetStartedEN from "../constants/translations/GetStarted/en/index.json";
import GetStartedDE from "../constants/translations/GetStarted/de/index.json";
import GetStartedGE from "../constants/translations/GetStarted/ge/index.json";

import MenuItemsEN from "../constants/translations/menuItems/en/index.json";
import MenuItemsDE from "../constants/translations/menuItems/de/index.json";
import MenuItemsGE from "../constants/translations/menuItems/ge/index.json";

import MyProfileEN from "../constants/translations/MyProfile/en/index.json";
import MyProfileDE from "../constants/translations/MyProfile/de/index.json";
import MyProfileGE from "../constants/translations/MyProfile/ge/index.json";

import PasswordResetEN from "../constants/translations/PasswordReset/en/index.json";
import PasswordResetDE from "../constants/translations/PasswordReset/de/index.json";
import PasswordResetGE from "../constants/translations/PasswordReset/ge/index.json";

import PatientsEN from "../constants/translations/Patients/en/index.json";
import PatientsDE from "../constants/translations/Patients/de/index.json";
import PatientsGE from "../constants/translations/Patients/ge/index.json";
import AddPatientEN from "../constants/translations/Patients/en/addPatients.json";
import AddPatientDE from "../constants/translations/Patients/de/addPatients.json";
import AddPatientGE from "../constants/translations/Patients/ge/addPatients.json";
import PatientsCardsEN from "../constants/translations/Patients/en/patientsCards.json";
import PatientsCardsDE from "../constants/translations/Patients/de/patientsCards.json";
import PatientsCardsGE from "../constants/translations/Patients/ge/patientsCards.json";

import SignInEN from "../constants/translations/SignIn/en/index.json";
import SignInDE from "../constants/translations/SignIn/de/index.json";
import SignInGE from "../constants/translations/SignIn/ge/index.json";

import SignUpEN from "../constants/translations/SignUp/en/index.json";
import SignUpDE from "../constants/translations/SignUp/de/index.json";
import SignUpGE from "../constants/translations/SignUp/ge/index.json";

import SignUpStep1EN from "../constants/translations/SignUp/en/step1.json";
import SignUpStep1DE from "../constants/translations/SignUp/de/step1.json";
import SignUpStep1GE from "../constants/translations/SignUp/ge/step1.json";

import SignUpStep2EN from "../constants/translations/SignUp/en/step2.json";
import SignUpStep2DE from "../constants/translations/SignUp/de/step2.json";
import SignUpStep2GE from "../constants/translations/SignUp/ge/step2.json";

import VisitorsEN from "../constants/translations/Visitors/en/index.json";
import VisitorsDE from "../constants/translations/Visitors/de/index.json";
import VisitorsGE from "../constants/translations/Visitors/ge/index.json";

import InviteVisitorEN from "../constants/translations/Visitors/en/inviteVisitor.json";
import InviteVisitorDE from "../constants/translations/Visitors/de/inviteVisitor.json";
import InviteVisitorGE from "../constants/translations/Visitors/ge/inviteVisitor.json";

import VisitorsCardsEN from "../constants/translations/Visitors/en/visitorsCards.json";
import VisitorsCardsDE from "../constants/translations/Visitors/de/visitorsCards.json";
import VisitorsCardsGE from "../constants/translations/Visitors/ge/visitorsCards.json";


import PatientStringsEN from '../constants/translations/Patients/en/addPatients.json';
import PatientStringsDE from '../constants/translations/Patients/de/addPatients.json';
import PatientStringsGE from '../constants/translations/Patients/ge/addPatients.json';

// the translations
export const resources = {
  en: {
    GetStarted: GetStartedEN,
    Agenda: AgendaEN,
    AddTimeSlot: { ...AddTimeSlotEN, ...DeleteTimeSlotEN, ...CommonActionsEN },
    DeleteTimeSlot: DeleteTimeSlotEN,
    TimeSlotCards: TimeSlotCardsEN,
    CancelAppointment: CancelAppointmentEN,
    ChooseTime: ChooseTimeEN,
    FeedbackDialog: FeedbackDialogEN,
    CommonInputs: CommonInputsEN,
    CommonActions: CommonActionsEN,
    ConfirmMail: ConfirmMailEN,
    DiaryBook: DiaryBookEN,
    AddDiaryBook: AddDiaryBookEN,
    EditUser: EditUserEN,
    MenuItems: MenuItemsEN,
    MyProfile: MyProfileEN,
    PasswordReset: PasswordResetEN,
    Patients: PatientsEN,
    AddPatient: AddPatientEN,
    PatientsCards: PatientsCardsEN,
    SignIn: SignInEN,
    SignUp: SignUpEN,
    SignUpStep1: SignUpStep1EN,
    SignUpStep2: SignUpStep2EN,
    Visitors: VisitorsEN,
    VisitorsCards: VisitorsCardsEN,
    InviteVisitor: { ...InviteVisitorEN, ...CommonInputsEN },
    PatientStrings: { ...PatientStringsEN, ...CommonInputsEN }
  },
  de: {
    GetStarted: GetStartedDE,
    Agenda: AgendaDE,
    AddTimeSlot: { ...AddTimeSlotDE, ...DeleteTimeSlotDE, ...CommonActionsDE },
    DeleteTimeSlot: DeleteTimeSlotDE,
    TimeSlotCards: TimeSlotCardsDE,
    CancelAppointment: CancelAppointmentDE,
    ChooseTime: ChooseTimeDE,
    FeedbackDialog: FeedbackDialogDE,
    CommonInputs: CommonInputsDE,
    CommonActions: CommonActionsDE,
    ConfirmMail: ConfirmMailDE,
    DiaryBook: DiaryBookDE,
    AddDiaryBook: AddDiaryBookDE,
    EditUser: EditUserDE,
    MenuItems: MenuItemsDE,
    MyProfile: MyProfileDE,
    PasswordReset: PasswordResetDE,
    Patients: PatientsDE,
    AddPatient: AddPatientDE,
    PatientsCards: PatientsCardsDE,
    SignIn: SignInDE,
    SignUp: SignUpDE,
    SignUpStep1: SignUpStep1DE,
    SignUpStep2: SignUpStep2DE,
    Visitors: VisitorsDE,
    VisitorsCards: VisitorsCardsDE,
    InviteVisitor: { ...InviteVisitorDE, ...CommonInputsDE },
    PatientStrings: { ...PatientStringsDE, ...CommonInputsDE }
  },
  ge: {
    GetStarted: GetStartedGE,
    Agenda: AgendaGE,
    AddTimeSlot: { ...AddTimeSlotGE, ...DeleteTimeSlotGE, ...CommonActionsGE },
    DeleteTimeSlot: DeleteTimeSlotGE,
    TimeSlotCards: TimeSlotCardsGE,
    CancelAppointment: CancelAppointmentGE,
    ChooseTime: ChooseTimeGE,
    FeedbackDialog: FeedbackDialogGE,
    CommonInputs: CommonInputsGE,
    CommonActions: CommonActionsGE,
    ConfirmMail: ConfirmMailGE,
    DiaryBook: DiaryBookGE,
    AddDiaryBook: AddDiaryBookGE,
    EditUser: EditUserGE,
    MenuItems: MenuItemsGE,
    MyProfile: MyProfileGE,
    PasswordReset: PasswordResetGE,
    Patients: PatientsGE,
    AddPatient: AddPatientGE,
    PatientsCards: PatientsCardsGE,
    SignIn: SignInGE,
    SignUp: SignUpGE,
    SignUpStep1: SignUpStep1GE,
    SignUpStep2: SignUpStep2GE,
    Visitors: VisitorsGE,
    VisitorsCards: VisitorsCardsGE,
    InviteVisitor: { ...InviteVisitorGE, ...CommonInputsGE },
    PatientStrings: { ...PatientStringsGE, ...CommonInputsGE }
  },
};