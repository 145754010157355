import React from 'react';

import Calendar from '../../../assets/icons/menuIcons/Calendar.png';
import Book from '../../../assets/icons/menuIcons/Book.png';
import DoorOpen from '../../../assets/icons/menuIcons/DoorOpen.png';
import User from '../../../assets/icons/menuIcons/User.png';
import CalendarFocused from '../../../assets/icons/menuIcons/CalendarFocused.png';
import BookFocused from '../../../assets/icons/menuIcons/BookFocused.png';
import DoorOpenFocused from '../../../assets/icons/menuIcons/DoorOpenFocused.png';
import UserFocused from '../../../assets/icons/menuIcons/UserFocused.png';

// import styles from './CustomMenuIcon.module.scss';

interface CustomIconI {
  type:string,
  focused: boolean
}

const CustomIcon:React.SFC<CustomIconI> = ( props ) => {
  switch ( props.type ) {
    case "calendar":
      if ( props.focused ) {
        return ( <img src={ CalendarFocused } alt="" /> )
      }
      return ( <img src={ Calendar } alt="" /> )
    case "book":
      if ( props.focused ) {
        return ( <img src={ BookFocused } alt="" /> )
      }
      return ( <img src={ Book } alt="" /> );
    case "doorOpen":
      if ( props.focused ) {
        return ( <img src={ DoorOpenFocused } alt="" /> )
      }
      return ( <img src={ DoorOpen } alt="" /> );
    case "user":
      if ( props.focused ) {
        return <img src={ UserFocused } alt="" />
      }
      return ( <img src={ User } alt="" /> );
    default:
      return ( <React.Fragment></React.Fragment> );
  }
};

export default CustomIcon;
