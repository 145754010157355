import React from 'react';
import styles from './UserIconButton.module.scss';
import { IconButton, withStyles } from '@material-ui/core';

const CustomButton = withStyles( {
  root: {
    'borderRadius': '50%',
    'font-family': 'Geomanist Medium',
    'font-weight': '500',
    'font-size': '16px',
    'text-transform': 'uppercase',
    'backgroundColor': '#ED145B',
    'margin': '4px 5px 3px',
    '&:hover': {
      'backgroundColor': 'rgba(237, 20, 91)'
    }
  },
} )( IconButton );


const UserIconButton = ( { userData, size } ) => (
  <CustomButton classes={ {
    root: size === "big" ? styles.bigButton : styles.smallButton
  } }>{ ( userData && userData.firstName ) ? ( userData.firstName[ 0 ] + userData.lastName[ 0 ] ) : "" }</CustomButton>
);


export default UserIconButton;
