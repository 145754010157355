import { FETCH_VISITORS, LOADING } from "../actionTypes";

const initState = {
  isLoading: true,
  visitorsData: []
}

const visitorsReducer = ( state = initState, action ) => {
  switch ( action.type ) {
    case FETCH_VISITORS:
      return {
        visitorsData: action.payload,
        isLoading: false,
      };

    case LOADING:
      return {
        ...state
      };

    default:
      return state;
  }
}

export default visitorsReducer;