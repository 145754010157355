import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import Select from 'react-select';
import styles from './AuthorisedLayout.module.scss';
import MenuItem from '../MenuItem/MenuItem';
import enFlag from '../../../assets/icons/countries/UK-flag-icon-64.png';
import deFlag from '../../../assets/icons/countries/DE-flag-icon-64.png';
import geFlag from '../../../assets/icons/countries/GE-flag-icon-64.png';

const AuthorisedLayout = ( { children, i18n } ) => {

  const options = [
    { value: "en", label: <div><img className={ styles.flag } src={ enFlag } alt="" />English</div> },
    { value: "ge", label: <div><img className={ styles.flag } src={ geFlag } alt="" />German</div> },
    { value: "de", label: <div><img className={ styles.flag } src={ deFlag } alt="" />Dutch</div> }
  ];

  const [ currentValue, changeCurrentValue ] = useState( options.find( opt => opt.value === i18n.languages[ 0 ].slice( 0, 2 ) ) || options[ 0 ] );

  const changeLanguage = lng => {
    i18n.changeLanguage( lng.value );
    changeCurrentValue( lng );
  };

  return (
    <div >
      <div className={ styles.AuthorisedLayout }>
        <div className={ styles.menu }>
          <MenuItem type="agenda"></MenuItem>
          <MenuItem type="visitors"></MenuItem>
          <MenuItem type="diaryBook"></MenuItem>
          <MenuItem type="profile"></MenuItem>

          <Select
            className={ styles.desktopLanguageSelector }
            classNamePrefix="select"
            defaultValue={ currentValue }
            value={ currentValue }
            isDisabled={ false }
            isLoading={ false }
            isClearable={ false }
            isRtl={ false }
            isSearchable={ false }
            name={ "language-selector" }
            options={ options }
            onChange={ ( val ) => { changeLanguage( val ) } }
          />
        </div>
        <div className={ styles.languageSelector }>
          <Select
            className={ styles.mobileLanguageSelector }
            classNamePrefix="select"
            defaultValue={ currentValue }
            value={ currentValue }
            isDisabled={ false }
            isLoading={ false }
            isClearable={ false }
            isRtl={ false }
            isSearchable={ false }
            name="language-selector"
            options={ options }
            onChange={ ( val ) => { changeLanguage( val ) } }
          />
        </div>
        <div className={ styles.childrenPage }>
          { children }
        </div>
      </div>
    </div>
  )
};

export default withNamespaces()( AuthorisedLayout );
