import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import vtpVisitImgMobile from '../../../assets/images/vtpVisitMobile.png';
import CustomInputField from '../../shared/InputField/CustomInputField';
import CustomButton from '../../shared/CustomButton/CustomButton';
import { inviteVisitor } from '../../../redux/actions/visitorsActions';
import AuthPagesTitle from '../../shared/AuthPagesTitle/AuthPagesTitle';

import styles from './InviteVisitor.module.scss';
import { withRouter } from 'react-router';

const InviteVisitor = ( { inviteVisitor, history, t, i18n } ) => {
  const [ name, setName ] = useState( "" );
  const [ email, setEmail ] = useState( "" );
  const onSubmit = () => {
    inviteVisitor( { name: name, email: email }, window.location.origin, i18n.languages[ 0 ].slice(0,2) );
    history.push( '/visitors' );
  }
  return (
    <div className={ styles.InviteVisitor }>
      <div className={ styles.mobileImageContainer }>
        <img src={ vtpVisitImgMobile } alt="" />
        <AuthPagesTitle newLine={ true } title={ t( 'title' ) }></AuthPagesTitle>
        <form onSubmit={ onSubmit }>
          <CustomInputField
            label={ t( "name" ) }
            value={ name }
            onChange={ ( e ) => setName( e.target.value ) }
            required>
          </CustomInputField>
          <CustomInputField
            label={ t( "email" ) }
            value={ email }
            onChange={ ( e ) => setEmail( e.target.value ) }
            type="email"
            required>
          </CustomInputField>
          <CustomButton type="submit">{ t( "submit" ) }</CustomButton>
        </form>
      </div>
    </div>
  )
};


const inviteVisitorAction = ( dispatch ) => {
  return {
    inviteVisitor: ( visitorData, originUrl, lng ) => dispatch( inviteVisitor( visitorData, originUrl, lng ) )
  }
}


export default connect( null, inviteVisitorAction )( withRouter( withNamespaces( "InviteVisitor" )( InviteVisitor ) ) );
