import React, { useState } from 'react';
import styles from './MyProfile.module.scss';
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorSharpIcon from '@material-ui/icons/BorderColorSharp';

import AccountImg from '../../assets/images/Account.png';
import { logOut, deleteUser, signIn } from '../../redux/actions/authActions';
import ConfirmDialog from '../shared/ConfirmDialog/ConfirmDialog';
import CustomInputField from '../shared/InputField/CustomInputField';
import CustomSnackbar from '../shared/CustomSnackbar/CustomSnackbar';

const MyProfile = ( { history, currentUser, t, ...props } ) => {

  const [ email, setEmail ] = useState( "" );
  const [ password, setPassword ] = useState( "" );
  const [ error, setErrors ] = useState( "" );

  const getInputs = () => (
    <div className={ styles.inputsContainer }>
      <CustomInputField
        value={ email }
        onChange={ e => setEmail( e.target.value ) }
        name="email"
        type="email"
        label={ t( 'email' ) }
        required
      />
      <CustomInputField
        onChange={ e => setPassword( e.target.value ) }
        name="password"
        value={ password }
        type="password"
        label={ t( 'password' ) }
        required
      />
    </div>
  )

  const editProfile = () => {
    history.push( '/profile/edit-account-details' );
  }
  const getConfirmDialog = () => {
    return {
      title: t( 'dialog title' ),
      description: t( 'dialog description' ),
      cancel: t( 'dialog cancel' ),
      confirm: t( 'dialog confirm' )
    }
  }
  const deleteUser = () => {
    props.logIn( email, password ).then( () => {
      props.deleteUser().then( () => {
        console.log( "user deleted" )
      } )
      history.push( '/' );
    } ).catch( error => {
      setErrors( `${error.message}` );
    } )
  }
  return (
    <div className={ styles.MyProfile }>
      <div className={ styles.Title } >
        <h1>{ currentUser && currentUser.userRole ? t( 'title' ) : t( 'caregiver title' ) }</h1>
        <BorderColorSharpIcon className={ styles.editButton } onClick={ editProfile }></BorderColorSharpIcon>
      </div>
      {
        currentUser &&
        <div className={ styles.Content }>
          <img src={ AccountImg } alt="" />
          <h1>{ currentUser.firstName } { currentUser.lastName }</h1>
          <span>{ currentUser.email }</span>
          { currentUser.institution &&
            <span>{ currentUser.institution },</span>
          }
          { currentUser.department &&
            <span>{ currentUser.department }, { t( 'chamber' ) } { currentUser.chamberNumber }</span>
          }
        </div>
      }
      <div className={ styles.ButtonsContainer }>
        <Button style={ { color: "#c7a2a0" } } variant="contained" fullWidth onClick={ props.logOut }>
          <ExitToAppIcon></ExitToAppIcon> <Typography>{ t( 'log out' ) }</Typography>
        </Button>
        <ConfirmDialog confirmAction={ deleteUser } name={ "" } strings={ getConfirmDialog() } inputs={ getInputs() }>
          <Button style={ { color: "#c7a2a0", marginTop: "15px" } } variant="contained" fullWidth >
            <DeleteIcon></DeleteIcon> <Typography>{ t( 'delete user' ) }</Typography>
          </Button>
        </ConfirmDialog>
      </div>
      <CustomSnackbar severity={ "error" } closeSnackBar={ () => setErrors( "" ) } >{ error }</CustomSnackbar>
    </div>
  )
};

const mapStateToProps = ( state ) => {
  return {
    currentUser: state.auth.currentUserData
  }
}

const logOutAction = ( dispatch ) => {
  return {
    logIn: ( email, password ) => dispatch( signIn( email, password ) ),
    logOut: () => dispatch( logOut() ),
    deleteUser: () => dispatch( deleteUser() )
  }
}

export default connect( mapStateToProps, logOutAction )( withRouter( withNamespaces( "MyProfile" )( MyProfile ) ) );
