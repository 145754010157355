
import Joi from 'joi-browser';

export const TextFieldValidator = ( fieldName ) => {
  return {
    value: Joi.string().required().error( err => {
      return {
        ...err,
        message: `${fieldName} is required`
      }
    } ), handler: Joi.any()
  }
}

export const PasswordFieldValidator = ( fieldName ) => {
  return {
    value: Joi.string().min( 6 ).required().error( errors => {
      errors.forEach( err => {
        if ( err && err.type ) {
          switch ( err.type ) {
            case 'any.empty':
              err.message = `${fieldName} is required`;
              break;
            case "string.min":
              err.message = `Password should be at least 6 characters`;
              break;
            default:
              break;
          }
        }
      } )
      return errors;
    } ), handler: Joi.any()
  }
}

export const BoolFieldValidator = ( message ) => {
  return {
    value: Joi.valid( true ).required().error( err => {
      return {
        ...err,
        message: `${message}`
      }
    } ), handler: Joi.any()
  }
}

export const EmailFieldValidator = ( fieldName ) => {
  return {
    value: Joi.string().required().email( { minDomainSegments: 2, tlds: { allow: [ 'com', 'net' ] } } ).error( errors => {
      errors.forEach( err => {
        switch ( err.type ) {
          case 'any.empty':
            err.message = `${fieldName} is required`;
            break;
          case "string.email":
            err.message = `Please enter a valid e-mail`;
            break;
          default:
            break;
        }
      } )
      return errors
    } )
    , handler: Joi.any()
  }
}


export default { TextFieldValidator, BoolFieldValidator, EmailFieldValidator, PasswordFieldValidator }