import React from 'react';

import TextField from '@material-ui/core/TextField';
import styles from './CustomInputField.module.scss';
import { withStyles } from '@material-ui/core/styles';

const CssTextField = withStyles( {
  root: {
    "color": "2C0915",
    // '& .MuiOutlinedInput-input': {
    //   'padding': '15.5px 14px'
    // },
    '& label.Mui-focused': {
      color: '#F2F2F2',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F2F2F2',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#F2F2F2',
      },
    },
  },
} )( TextField );

const CustomInputField = ( { value, onChange, name, type, errorMessage, textArea, ...rest } ) => {
  let error = errorMessage || "",
    helperText = errorMessage;
  return (
    <div className={ styles.CustomInputFieldContainer }>
      {
        <CssTextField
          className={ styles.CustomInputField }
          value={ value }
          onChange={ onChange }
          name={ name }
          type={ type }
          variant="outlined"
          fullWidth
          multiline={ textArea }
          rows="7"
          onFocus={ () => error = "" }
          error={ error !== "" }
          helperText={ helperText }
          { ...rest }
        />
      }
    </div>
  )
};

export default CustomInputField;
