import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import CustomButton from '../CustomButton/CustomButton';
import styles from './LinkButton.module.scss';

interface linkButtonI {
  children: React.LinkHTMLAttributes<HTMLButtonElement>,
  buttonProps?: ButtonProps,
  fullWidth: boolean,
  to: string
}

const LinkButton: React.SFC<linkButtonI> = ({ children, buttonProps, fullWidth, ...rest }) => {
  return (
    <Link className={clsx(styles.LinkButton, fullWidth && styles.fullWidth)} {...rest}>
      <CustomButton {...buttonProps}>
        {children}
      </CustomButton>
    </Link>
  )
};

export default LinkButton;
