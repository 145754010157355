import React from 'react';
import { withNamespaces } from 'react-i18next';

import PatientImg from "../../../assets/images/Patient.png";
import CaregiverImg from "../../../assets/images/Caregiver.png";

import styles from './Step2.module.scss';

const Step2 = ( { t, ...props } ) => {

  if ( props.currentStep.value !== "2" ) {
    return null
  }

  const handleSelect = ( userRole, event ) => {
    props.handleForm( event, userRole );
  }

  return (
    <div className={ styles.Step2 }>
      <div className={ styles.Option } onClick={ ( e ) => handleSelect( "patient", e ) }>
        <img src={ PatientImg } alt={ t( "patient title" ) }></img>
        <div className={ styles.textContainer }>
          <h3>{ t( "patient title" ) }</h3>
          <span>{ t( "patient text 1" ) }</span>
          <span>{ t( "patient text 2" ) }</span>
        </div>
      </div>
      <div className={ styles.or }>{ t('or') }</div>
      <div className={ styles.Option } onClick={ ( e ) => handleSelect( "caregiver", e ) }>
        <img src={ CaregiverImg } alt={ t( "caregiver title" ) }></img>
        <div className={ styles.textContainer }>
          <h3>{ t( "caregiver title" ) }</h3>
          <span>{ t( "caregiver text" ) }</span>
        </div>
      </div>
    </div>
  )
};

export default withNamespaces( "SignUpStep2" )( Step2 );
