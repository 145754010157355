import React from 'react';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import styles from './DiaryBookCards.module.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

const DiaryBookCards = ( { diaryNotes, history, viewOnly, currentUser, i18n } ) => {

  const editDiaryNote = ( diaryNote ) => {
    history.push( `/diary-book/edit?id=${diaryNote.id}` );
  }

  const getPrettyDate = ( d ) => {
    const date = new Date( d );
    const languages = { en: "en-GB", ge: 'de-DE', de: 'nl-NL' }
    try {
      return new Intl.DateTimeFormat( languages[i18n.languages[0]], {
        weekday: 'long',
        month: "long",
        day: "2-digit",
        year: "numeric"
      } ).format( date )
    }
    catch {
      return new Intl.DateTimeFormat( languages[i18n.languages[0]], { weekday: 'long', month: "long", day: "2-digit" } ).format( new Date() )
    }
  }

  const getCard = ( diaryNote ) => {
    return (
      <div key={ diaryNote.id } onClick={ () => currentUser.firstName ? editDiaryNote( diaryNote ) : console.log("Unauthorised") } className={ clsx( styles.Card, currentUser.firstName && styles.clickable) }>
        <div className={ styles.Date }>{ getPrettyDate(diaryNote.data.date) }</div>
        <h3>{ diaryNote.data.title }</h3>
        { viewOnly ? <MoreHorizIcon className={ styles.editIcon }></MoreHorizIcon> : <React.Fragment></React.Fragment> }
        <div className={ styles.text }>{ diaryNote.data.text }</div>
      </div>
    )
  }

  const getDiaryNotesCards = () => {
    const DiaryNotesCards = [];
    for ( const diaryNote in diaryNotes ) {
      DiaryNotesCards.push( getCard( diaryNotes[ diaryNote ] ) )
    }
    return DiaryNotesCards;
  }

  return (
    <div className={ styles.DiaryBookCards }>
      { getDiaryNotesCards() }
    </div>
  )
};

const mapStateToProps = ( state ) => {
  return {
    currentUser: state.auth.currentUserData
  }
}

export default connect(mapStateToProps)( withRouter( withNamespaces () (DiaryBookCards) ) );
