import React from 'react';
import styles from './AuthPagesTitle.module.scss';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import clsx from 'clsx';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import UserIconButton from '../../shared/UserIconButton/UserIconButton';
import { withNamespaces } from 'react-i18next';

const AuthPagesTitle = ( { t, title, history, path, newLine } ) => {
  const currentUserData = useSelector( state => state.auth.currentUserData ),
    userName = currentUserData ? ( currentUserData.userRole === "patient" ? t( "Me" ) : `${currentUserData.firstName} ${currentUserData.lastName}` ) : "";

  if ( currentUserData.userRole !== "patient" && path !== "/agenda/select-patient" && ( userName === "undefined undefined" ) ) {
    history.push( "/agenda/select-patient" )
  }
  const onClick = () => {
    currentUserData.userRole === "patient" ? history.push( '/profile' ) : history.push( '/agenda/select-patient' );
  }
  return (
    <div className={ clsx( styles.AuthPagesTitle, newLine && styles.newLine ) }>
      <h1>{ title }</h1>
      <div className={ styles.userData } onClick={ onClick }>
        <KeyboardArrowDownIcon className={ styles.keyboardArrowDown }></KeyboardArrowDownIcon>
        { currentUserData.firstName && <span name={ "userName" } className={ styles.userName }>{ userName }</span> }
        <UserIconButton userData={ currentUserData }></UserIconButton>
      </div>
    </div>
  )
};


export default withRouter( withNamespaces( "MenuItems" )( AuthPagesTitle ) );
