import { EDIT_USER_DETAILS, ADD_PATIENT, SET_CURRENT_USER, FETCH_PATIENTS, DELETE_PATIENT, LOADING } from "../actionTypes";

const setUser = ( dispatch, data, userId ) => {
  if ( userId ) {
    const currentUserData = {};
    if ( data ) {
      localStorage.setItem( 'userId', userId );
    }
    if ( data && data.userRole !== "caregiver" ) {
      currentUserData.firstName = data.firstName;
      currentUserData.lastName = data.lastName;
      currentUserData.userRole = data.userRole;
      currentUserData.email = data.email;
      currentUserData.institution = data.institution;
      currentUserData.department = data.department;
      currentUserData.chamberNumber = data.chamberNumber;
      currentUserData.confirmedMail = data.confirmedMail;
    }
    dispatch( { type: SET_CURRENT_USER, payload: currentUserData } );
  }
}

const setCurrentUser = ( patientId, uid ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase(),
      userId = firebase.auth().currentUser ? firebase.auth().currentUser.uid : uid;
    return patientId && userId && userId !== patientId && patientId !== undefined && userId !== undefined ?
      firebase.ref( `patients/${userId}/${patientId}` ).on( "value", ( snapshot ) => setUser( dispatch, snapshot.val(), patientId ) )
      :
      firebase.ref( `users/${userId}` ).on( "value", ( snapshot ) => setUser( dispatch, snapshot.val(), userId ) )
  }
}



const setUserForEmail = (uid) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    return firebase.ref( `users/${uid}` ).on( "value", ( snapshot ) => setUser( dispatch, {...snapshot.val(), userRole: "user"}, uid ) )
  }
}

const editUserDetails = ( userDetails, patientId ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase(),
      accountId = firebase.auth().currentUser.uid;
    return firebase.ref( accountId === patientId ? `users/${accountId}` : `patients/${accountId}/${patientId}` )
      .update( userDetails ).then( ( response ) => {
        const data = response === undefined ? '' : response;
        dispatch( { type: EDIT_USER_DETAILS, data } );
      } ).catch( error => {
        dispatch( { type: `${EDIT_USER_DETAILS}_ERROR`, error } );
        return Promise.reject( error );
      } )
  }
}

const addPatient = ( patientDetails ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    return firebase.ref( `patients/${user.uid}` ).push( patientDetails ).then( ( response ) => {
      const payload = response === undefined ? '' : response;
      dispatch( { type: ADD_PATIENT, payload } );
    } ).catch( error => {
      dispatch( { type: `${ADD_PATIENT}_ERROR`, error } );
      return Promise.reject( error );
    } )
  }
}

const fetchPatients = () => {
  return ( dispatch, _getState, getFirebase ) => {
    dispatch( { type: LOADING, payload: true } )
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    return firebase.ref( `patients/${user.uid}` ).on( "value", ( snapshot ) => {
      const data = snapshot.val();

      const patients = [];
      for ( const propertyKey in data ) {
        patients.push(
          {
            id: propertyKey,
            data: data[ propertyKey ]
          }
        );
      }
      dispatch( { type: FETCH_PATIENTS, payload: patients } );
      dispatch( { type: LOADING, payload: false } );
    } );
  }
}

const deletePatient = ( patientId ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    return firebase.ref( `patients/${user.uid}/${patientId}` ).remove().then( ( response ) => {
      const payload = response === undefined ? '' : response;
      dispatch( { type: DELETE_PATIENT, payload } );
    } ).catch( error => {
      dispatch( { type: `${DELETE_PATIENT}_ERROR`, error } );
      return Promise.reject( error );
    } )
  }
}

export { editUserDetails, addPatient, setCurrentUser, fetchPatients, deletePatient, setUserForEmail }
