import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import unauthorisedRoutes from "./unauthorisedRoutes";
import privateRoutes from "./privateRoutes";
import UnauthorisedRoute from './UnauthorisedRoute';
import PrivateRoute from './PrivateRoute';
import publicRoutes from './publicRoutes';
import PublicRoute from './PublicRoute';

class Router extends React.Component {
  render() {

    const getUnauthorisedRoutes = () => unauthorisedRoutes.map( ( { path, Component, exact, components, redirect }, i ) => (
      <UnauthorisedRoute
        path={ path }
        key={ "unAuth" + i }
        exact={ exact || false }
      >
        <Component></Component>
      </UnauthorisedRoute>
    ) );

    const getAuthorisedRoutes = () => privateRoutes.map( ( { path, Component, exact, components, redirect, noMenu }, i ) => {
      if ( !Component && !components && !redirect ) { Component = () => ( <div></div> ) }
      return Component ?
        (
          <PrivateRoute
            path={ path }
            key={ "pr" + i }
            exact={ exact || false }
            noMenu={ noMenu }
          >
            <Component></Component>
          </PrivateRoute>
        )
        :
        components ?
          (
            <PrivateRoute
              path={ path }
              key={ "prc" + i }
              exact={ exact || false }
              noMenu={ noMenu }
            >
              {
                components.map( ( { path, Component, exact }, ci ) => {
                  return (
                    <Route
                      path={ path }
                      key={ "prc" + i + ci }
                      exact={ exact }
                    >
                      <Component></Component>
                    </Route>
                  )
                } )
              }
            </PrivateRoute>
          )
          :
          <PrivateRoute key={ "render" + i } render={ () => <Redirect to={ redirect } /> }></PrivateRoute>
    } );

    const getPublicRoutes = () => publicRoutes.map( ( { path, Component, exact=false }, i ) => {
      return (
        <PublicRoute
          path={ path }
          key={ 'pu' + i }
          exact={ exact }>
          <Component></Component>
        </PublicRoute>
      )
    } )

    return (
      <BrowserRouter>
        <Switch>
          {
            getPublicRoutes()
          }
          {
            getUnauthorisedRoutes()
          }
          {
            getAuthorisedRoutes()
          }
        </Switch>
      </BrowserRouter >
    )
  }
}

export default Router;
