import { ADD_TIME_SLOT, EDIT_TIME_SLOT, FETCH_TIME_SLOT, LOADING } from "../actionTypes";

const initState = {
  isLoading: true,
  timeSlots: []
}

const timeSlotsReducer = ( state = initState, action ) => {
  switch ( action.type ) {
    case FETCH_TIME_SLOT:
      return {
        timeSlots: action.payload,
        isLoading: false,
      };

    case ADD_TIME_SLOT:
      return {
        ...state,
        isLoading: false,
      };

    case EDIT_TIME_SLOT:
      return {
        ...state,
        isLoading: false,
      };

    case LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    default:
      return state;
  }
}

export default timeSlotsReducer;
