import { ADD_TIME_SLOT, FETCH_TIME_SLOT, EDIT_TIME_SLOT, DELETE_TIME_SLOT, LOADING, SCHEDULE_APPOINTMENT, CANCEL_APPOINTMENT } from "../actionTypes";
import axios from 'axios';

const addNewTimeSlot = ( timeSlotData, userId ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    userId = userId ? userId : firebase.auth().currentUser.uid;
    return firebase.ref( `timeSlots/${userId}` ).push( timeSlotData ).then( ( response ) => {
      const data = response === undefined ? '' : response;
      dispatch( { type: ADD_TIME_SLOT, payload: data } )
    } ).catch( error => {
      dispatch( { type: `${ADD_TIME_SLOT}_ERROR`, error } );
      return Promise.reject( error );
    } )
  }
}

const editTimeSlot = ( timeSlot, userId ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    userId = userId ? userId : firebase.auth().currentUser.uid;
    return firebase.ref( `timeSlots/${userId}/${timeSlot.id}` ).update( timeSlot.data ).then( ( response ) => {
      const data = response === undefined ? '' : response;
      dispatch( { type: EDIT_TIME_SLOT, payload: data } )
    } ).catch( error => {
      dispatch( { type: `${EDIT_TIME_SLOT}_ERROR`, error } );
      return Promise.reject( error );
    } )
  }
}

const sendDeleteNotification = ( user, dest, lng, resolve ) => {
  const userName = `${user.firstName} ${user.lastName}`;
  return axios.get( `https://us-central1-visit-the-patient.cloudfunctions.net/timeSlotDeleted?userName=${userName}&userEmail=${user.email}&dest=${dest}&lng=${lng}` ).then( () => {
    // return axios.get( `http://localhost:5001/visit-the-patient/us-central1/timeSlotDeleted?userName=${userName}&userEmail=${user.email}&dest=${dest}&lng=${lng}` ).then( () => {
    return resolve();
  } )
}

const sendDeletedTimeSlotNotification = ( firebase, bookedBy, patientId, lng ) => {
  return new Promise( ( resolve ) => {
    if ( bookedBy === "" ) {
      return resolve();
    }
    return firebase.ref( `visitors/${patientId}/${bookedBy}` ).on( 'value', ( snapshot ) => {
      if ( snapshot.val() ) {
        const dest = snapshot.val().email,
          userId = firebase.auth().currentUser ? firebase.auth().currentUser.uid : "";
        return patientId && userId && userId !== patientId && patientId !== undefined && userId !== undefined ?
          firebase.ref( `patients/${userId}/${patientId}` ).on( "value", ( snapshot ) => sendDeleteNotification( snapshot.val(), dest, lng, resolve ) )
          :
          firebase.ref( `users/${userId}` ).on( "value", ( snapshot ) => sendDeleteNotification( snapshot.val(), dest, lng, resolve ) )
      }
    } )
  } )
}

const deleteTimeSlot = ( timeSlotId, userId, lng, bookedBy = "" ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    userId = userId ? userId : firebase.auth().currentUser.uid;
    return sendDeletedTimeSlotNotification( firebase, bookedBy, userId, lng ).then( () => {
      return firebase.ref( `timeSlots/${userId}/${timeSlotId}` ).remove().then( ( response ) => {
        const data = response === undefined ? '' : response;
        dispatch( { type: DELETE_TIME_SLOT, payload: data } );
      } ).catch( error => {
        dispatch( { type: `${DELETE_TIME_SLOT}_ERROR`, error } );
        return Promise.reject( error );
      } )
    } )
  }
}

const fetchTimeSlots = ( userId ) => {
  return ( dispatch, _getState, getFirebase ) => {
    dispatch( { type: LOADING, payload: true } );
    const firebase = getFirebase();
    userId = userId ? userId : firebase.auth().currentUser.uid;
    firebase.ref( `timeSlots/${userId}` ).on( 'value', ( snapshot ) => {
      const data = snapshot.val();

      const timeSlots = [];
      for ( const propertyKey in data ) {
        timeSlots.push(
          {
            id: propertyKey,
            data: data[ propertyKey ]
          }
        );
      }
      dispatch( { type: FETCH_TIME_SLOT, payload: timeSlots } );
      dispatch( { type: LOADING, payload: false } );
    } );
  }
}

const scheduleAppointment = ( userId, patientId, visitorId, timeSlotId, patientName, lng ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    firebase.ref( `timeSlots/${patientId}/${timeSlotId}/` ).update( { bookedBy: visitorId }, () => {
      firebase.ref( `visitors/${patientId}/${visitorId}/` ).on( 'value', ( snapshot ) => {
        const dest = snapshot.val().email;
        const cancelLink = `${window.location.origin}/cancel-appointment?timeSlotId=${timeSlotId}%26patientId=${patientId}%26visitorId=${visitorId}%26userId=${userId}%26lng=${lng}`;
        return axios.get( `https://us-central1-visit-the-patient.cloudfunctions.net/confirmAppointment?patientName=${patientName}&dest=${dest}&link=${cancelLink}&lng=${lng}` )
        .then( ( response ) => {
            dispatch( { type: SCHEDULE_APPOINTMENT, payload: response } );

          } )
      } )
    } )
  }
}

const cancelAppointment = ( patientId, timeSlotId, visitorName, patientEmail, lng ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    firebase.ref( `timeSlots/${patientId}/${timeSlotId}/` ).update( { bookedBy: "" }, ( snapshot ) => {
      return axios.get( `https://us-central1-visit-the-patient.cloudfunctions.net/cancelAppointment?visitorName=${visitorName}&dest=${patientEmail}&lng=${lng}` )
        // return axios.get( `http://localhost:5001/visit-the-patient/us-central1/cancelAppointment?visitorName=${visitorName}&dest=${patientEmail}&lng=${lng}` )
        .then( ( response ) => {
          dispatch( { type: CANCEL_APPOINTMENT, payload: snapshot } )
        } )
    } )

  }
}

export { addNewTimeSlot, fetchTimeSlots, editTimeSlot, deleteTimeSlot, scheduleAppointment, cancelAppointment }
