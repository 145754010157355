import React from 'react';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import clsx from 'clsx';

import CustomMenuIcon from '../CustomMenuIcon/CustomMenuIcon';
import styles from './MenuItem.module.scss';

let types = {
  "agenda": { link: "/agenda", iconType: "calendar" },
  "visitors": { link: "/visitors", iconType: "book" },
  "diaryBook": { link: "/diary-book", iconType: "doorOpen" },
  "profile": { link: "/profile", iconType: "user" }
}

interface MenuItemI {
  type: "agenda" | "visitors" | 'diaryBook' | 'profile',
  t: any
}

const MenuItem: React.FC<WithNamespaces & RouteComponentProps & MenuItemI> = (props) => {
  const { type, t, location } = props;
  const { link, iconType } = types[type];
  return (
    <NavLink to={link} className={clsx(styles.userButton, location.pathname.includes(link) && styles.selected)}>
      <CustomMenuIcon type={iconType} focused={location.pathname.includes(link)}></CustomMenuIcon>
      <span>{t(type)}</span>
    </NavLink>
  )
};



export default withRouter(withNamespaces("MenuItems")(MenuItem));
