import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { getFirebase } from 'react-redux-firebase';
import { rootReducer } from './reducers';



const middlewares = [
  thunk.withExtraArgument(getFirebase)
];


// const initialState = {};

const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares)
);

export default store;
