import React from 'react';
import clsx from 'clsx';
import styles from './CustomCard.module.scss';

const CustomCard = ( { title, content, content2, rounded = true, menu = [], icon = ( <React.Fragment></React.Fragment> ), clickable = true, textarea = false, ...props } ) => {
  const getCustomCardClasses = () => clsx(
    styles.CustomCard,
    props.selected && styles.Selected,
    clickable && styles.Clickable,
    props.highlight && styles.highlight,
    textarea && styles.textareaContent
  )
  return (
    <div
      className={ getCustomCardClasses() }
      { ...props }>
      { icon }
      <div className="content">
        <div className={ styles.Title }>
          { title }
        </div>
        <div className={ styles.Content }>
          <div>
            { content }
          </div>
          {
            content2 &&
            <div>
              { content2 }
            </div>
          }
        </div>
      </div>
      { props.action }
      {
        props.selected &&
        <div className={ styles.hr }>

        </div>
      }
    </div>
  )
};

export default CustomCard;
