import React from "react";
import { Route, withRouter } from "react-router-dom";
import { isLoaded } from "react-redux-firebase";
import { connect } from "react-redux";

import { fetchTimeSlots } from '../redux/actions/timeSlotsActions';
import { setCurrentUser, setUserForEmail } from "../redux/actions/userActions";
import { fetchDiaryNotes } from "../redux/actions/diaryNotes";
import { fetchVisitors } from "../redux/actions/visitorsActions";
import AuthorisedLayout from "../components/shared/AuthorisedLayout/AuthorisedLayout";

const PublicRoute = ( { children, auth, path, fetchTimeSlots, setCurrentUser, fetchDiaryNotes, fetchVisitors, setUserForEmail, ...props } ) => {
  React.useEffect( () => {
    if ( isLoaded( auth ) ) {
      const patientId = new URLSearchParams( props.location.search ).get( 'patientId' );
      const uid = new URLSearchParams( props.location.search ).get( 'userId' );
      switch ( path ) {
        case '/visit':
          fetchTimeSlots( patientId );
          setUserForEmail( uid );
          fetchVisitors( patientId );
          break;
        case '/verify':
          const userId = new URLSearchParams( props.location.search ).get( 'id' );
          setCurrentUser( userId );
          break;
        case '/view-diary-notes':
          const id = new URLSearchParams( props.location.search ).get( 'id' );
          fetchDiaryNotes( id );
          break;
        case '/cancel-appointment':
          setUserForEmail( uid );
          fetchVisitors( patientId );
          break;
        default:
          break;
      }
    }
  }, [ path, auth, fetchTimeSlots, props.location.search, fetchDiaryNotes, setCurrentUser, fetchVisitors ] );
  const noMenu = true;//localStorage.getItem( 'userId' ) ? false : props.noMenu;
  return (
    <Route
      { ...props }
      render={ () =>
        isLoaded( auth ) ? (
          noMenu ?
            (
              <React.Fragment>{ children }</React.Fragment>
            )
            :
            (
              <AuthorisedLayout>{ children }</AuthorisedLayout>
            ) ) :
          (
            <React.Fragment></React.Fragment>
          )
      }
    ></Route>
  )
};

const privateRouteActions = ( dispatch ) => {
  return {
    fetchTimeSlots: ( id ) => dispatch( fetchTimeSlots( id ) ),
    setCurrentUser: ( id, userId ) => dispatch( setCurrentUser( id, userId ) ),
    setUserForEmail: ( id ) => dispatch( setUserForEmail( id ) ),
    fetchDiaryNotes: ( id ) => dispatch( fetchDiaryNotes( id ) ),
    fetchVisitors: ( id ) => dispatch( fetchVisitors( id ) )
  }
}


const mapStateToProps = ( state ) => {
  return {
    auth: state.firebase.auth
  }
}


export default connect( mapStateToProps, privateRouteActions )( withRouter( PublicRoute ) );