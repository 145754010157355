export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const START_DELETE = "START_DELETE";
export const DELETE_USER = "DELETE_USER";
export const RESET_STATE = "RESET_STATE";

export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const SET_PASSWORD_RESETED = "SET_PASSWORD_RESETED";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";
export const ADD_PATIENT = "ADD_PATIENT";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const FETCH_PATIENTS = "FETCH_PATIENTS";
export const DELETE_PATIENT = "DELETE_PATIENT";

export const ADD_TIME_SLOT = "ADD_TIME_SLOT";
export const FETCH_TIME_SLOT = "FETCH_TIME_SLOT";
export const EDIT_TIME_SLOT = "EDIT_TIME_SLOT";
export const DELETE_TIME_SLOT = "DELETE_TIME_SLOT";

export const LOADING = "LOADING";

export const INVITE_VISITOR = "INVITE_VISITOR";
export const FETCH_VISITORS = "FETCH_VISITORS";

export const SCHEDULE_APPOINTMENT = "SCHEDULE_APPOINTMENT";
export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT";

export const CONFIRM_MAIL = "CONFIRM_MAIL";

export const FETCH_DIARY_NOTES = "FETCH_DIARY_NOTES";
export const ADD_DIARY_NOTE = "ADD_DIARY_NOTE";
export const EDIT_DIARY_NOTE = "EDIT_DIARY_NOTE";
export const DIARY_NOTES_LOADING = "DIARY_NOTES_LOADING";
