import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { isLoaded } from 'react-redux-firebase';
import { withNamespaces } from 'react-i18next';

import AuthPagesTitle from '../shared/AuthPagesTitle/AuthPagesTitle';
import CustomButton from '../shared/CustomButton/CustomButton';
import noTimeSlotsImage from '../../assets/images/noTimeSlotsImage.png';
import TimeSlotsAgendaCards from './TimeSlotsAgendaCards/TimeSlotsAgendaCards';
import styles from './Agenda.module.scss';


const Agenda = ( { history, timeSlots, visitors, t } ) => {
  const getStortedTimeslot = () => {
    return timeSlots.timeSlots.sort((timeslot1, timeslot2) => {
      if ( timeslot1.data.date < timeslot2.data.date ) {
        return -1
      }
      return 1
    })
  }
  return (
    <div className={ styles.Agenda }>
      <AuthPagesTitle title={ t( 'title' ) }></AuthPagesTitle>
      {
        isLoaded( timeSlots ) && !timeSlots.isLoading ? (
          timeSlots.timeSlots.length ?
            (
              <TimeSlotsAgendaCards timeSlots={ getStortedTimeslot() } visitors={ visitors.visitorsData }></TimeSlotsAgendaCards>
            ) :
            (
              <div className={ styles.noTimeSlots }>
                <img src={ noTimeSlotsImage } alt="" />
                <h2>{ t( 'no time slots' ) }</h2>
                <span>{ t( 'add your first' ) }</span>
              </div>
            ) )
          :
          (
            <React.Fragment></React.Fragment>
          )

      }
      <CustomButton onClick={ () => { history.push( "/agenda/add" ) } } iconType={ "sum" }>{ t( 'add time slot' ) }</CustomButton>
    </div>
  )
};

const mapStateToProps = ( state ) => {
  return {
    timeSlots: state.timeSlots,
    visitors: state.visitors
  }
}

export default connect( mapStateToProps )( withRouter( withNamespaces( "Agenda" )( Agenda ) ) );
