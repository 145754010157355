import { LOG_IN, LOG_OUT, RESET_PASSWORD, SET_PASSWORD_RESETED, ADD_USER, SET_CURRENT_USER, LOADING, CONFIRM_MAIL, START_DELETE, DELETE_USER, RESET_STATE } from "../actionTypes";
import axios from 'axios';

const addUser = ( newUser, lng ) => {
  return ( dispatch, _getState, getFirebase ) => {
    dispatch( { type: LOADING, payload: true } );
    const firebase = getFirebase(),
      { password, ...newUserData } = newUser,
      { email } = newUser;
    return firebase.auth().createUserWithEmailAndPassword( email, password ).then( ( value ) => {
      const userId = value.user.uid;
      localStorage.setItem( 'userId', userId );
      return firebase.ref( `users/${userId}` ).set( {
        ...newUserData,
        confirmedMail: false
      } ).then( () => {
        const name = `${newUserData.firstName} ${newUserData.lastName}`,
          originUrl = window.location.origin;
        return axios.get( `https://us-central1-visit-the-patient.cloudfunctions.net/veriFyEmail?dest=${email}&name=${name}&lng=${lng}&link=${originUrl}/verify?id=${userId}%26lng=${lng}` )
          // return axios.get( `http://localhost:5001/visit-the-patient/us-central1/veriFyEmail?dest=bercean.ioan@gmail.com&name=${name}&lng=${lng}&link=${originUrl}/verify?id=${userId}%26lng=${lng}` )
          .then( () => {
            return firebase.auth().signInWithEmailAndPassword( email, password ).then( ( response ) => {
              const data = response === undefined ? '' : response;
              dispatch( { type: ADD_USER, data } );
              dispatch( { type: LOADING, payload: false } );
              return Promise.resolve( response );
            } )
          } ).catch( error => {
            dispatch( { type: `${ADD_USER}_ERROR`, error } );
            dispatch( { type: LOADING, payload: false } );
            return Promise.reject( error );
          } )
      } )
    } )
  }
}

const signIn = ( email, password ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    return firebase.auth().signInWithEmailAndPassword( email, password ).then( ( response ) => {
      localStorage.removeItem( 'userId' );
      const data = response === undefined ? '' : response.user;
      localStorage.setItem( 'userId', data.uid );
      firebase.ref( `/users/${data.uid}` ).on( 'value', ( snapshot ) => {
        dispatch( { type: SET_CURRENT_USER, payload: snapshot.val() } );
      } )
      return Promise.resolve( data );
    } ).catch( error => {
      dispatch( { type: `${LOG_IN}_ERROR`, error } );
      return Promise.reject( error );
    } )
  }
}

const resetPassword = ( email ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const auth = getFirebase().auth();
    return auth.sendPasswordResetEmail( email ).then( ( response ) => {
      const data = response === undefined ? '' : response;
      dispatch( { type: RESET_PASSWORD, data } )
      return Promise.resolve( data );
    } ).catch( error => {
      console.log( `err: ${error}` );
      return Promise.reject( error );
    } )
  }
}

const logOut = () => {
  return ( dispatch, _getState, getFirebase ) => {
    const auth = getFirebase().auth();
    return auth.signOut().then( ( response ) => {
      localStorage.removeItem( 'userId' );
      const data = response === undefined ? '' : response;
      dispatch( { type: LOG_OUT, data } )
      return Promise.resolve( data );
    } ).catch( error => {
      console.log( `err: ${error}` );
      dispatch( { type: `${LOG_OUT}_ERROR`, error } );
      return Promise.reject( error );
    } )
  }
}

const confirmMail = ( userId ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    return firebase.ref( `users/${userId}` )
      .update( { confirmedMail: true } ).then( ( response ) => {
        const data = response === undefined ? '' : response;
        dispatch( { type: CONFIRM_MAIL, data } );
      } ).catch( error => {
        dispatch( { type: `${CONFIRM_MAIL}_ERROR`, error } );
        return Promise.reject( error );
      } )
  }
}

const resetPasswordReseted = () => {
  return {
    type: SET_PASSWORD_RESETED,
    payload: false
  }
}

const deleteUser = () => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase(),
      auth = firebase.auth(),
      userId = auth.currentUser ? auth.currentUser.uid : localStorage.getItem( 'userId' );;
    dispatch( { type: START_DELETE } );


    return Promise.all( [
      firebase.ref( `users/${userId}` ).remove(),
      firebase.ref( `diaryNotes/${userId}` ).remove(),
      firebase.ref( `patients/${userId}` ).remove(),
      firebase.ref( `timeSlots/${userId}` ).remove(),
      firebase.ref( `visitors/${userId}` ).remove()
    ] ).then( () => {

      return auth.currentUser.delete().then( () => {
        dispatch( { type: DELETE_USER, payload: true } );
        return auth.currentUser.signOut().then( () => {
          return Promise.resolve();
        } )
      } )
    } ).catch( ( e ) => {
      console.error( e );
      return auth.currentUser.delete().then( () => {
        dispatch( { type: DELETE_USER, payload: true } );
        return auth.currentUser.signOut().then( () => {
          return Promise.resolve( e.message );
        } )
      } )
    } )


  }
}

const resetUserState = () => {
  return {
    type: RESET_STATE
  }
}

export { addUser, signIn, resetPassword, logOut, confirmMail, resetPasswordReseted, deleteUser, resetUserState }
