import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import Form from '@material-ui/core/FormControl';

import { addUser } from '../../redux/actions/authActions';
import styles from './SignUp.module.scss';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import UnauthorisedLayout from '../shared/UnauthorisedLayout/UnauthorisedLayout';
import validator from '../../utils/validator';

import Joi from 'joi-browser';

const schema = Joi.object().keys( {
  firstName: validator.TextFieldValidator( "First name" ),
  lastName: validator.TextFieldValidator( "Last name" ),
  email: validator.EmailFieldValidator( "Email" ),
  password: validator.PasswordFieldValidator( "Password" ),
  acceptedTC: validator.BoolFieldValidator( "You must accept terms & conditions to proced" ),
} ).unknown( true );


const SignUp = ( { t, i18n, ...props } ) => {
  const [ currentStep, setCurrentStep ] = useState( "1" );
  const [ firstName, setFirstName ] = useState( "" );
  const [ lastName, setLastName ] = useState( "" );
  const [ email, setEmail ] = useState( "" );
  const [ password, setPassword ] = useState( "" );
  const [ acceptedTC, setacceptedTC ] = useState( "" );

  const [ error, setErrors ] = useState( "" );

  const handleForm = ( event, userRole ) => {
    event.preventDefault();
    const user = { firstName, lastName, email, password, userRole, verified: false };
    props.addUser( user, i18n.languages[0] ).then( () => {
      setErrors( "" );
    } ).catch( error => {
      if ( error.code && error.code.includes( 'auth' ) ) {
        setCurrentStep( "1" );
      }
      console.log( error );
      setErrors( `${error.message}` );
    } );
  };

  const validate = ( callBack ) => {
    return Joi.validate( signUpProps, schema, callBack );
  }

  const signUpProps = {
    currentStep: { value: currentStep, handler: setCurrentStep },
    firstName: { value: firstName, handler: setFirstName },
    lastName: { value: lastName, handler: setLastName },
    email: { value: email, handler: setEmail },
    password: { value: password, handler: setPassword },
    acceptedTC: { value: Boolean( acceptedTC ), handler: setacceptedTC },
    validate: validate,
    handleForm: handleForm,
    setFormErrors: setErrors
  };

  const showMobileImg = currentStep !== "1" ? false : true,
    fullMobileTitle = currentStep !== "1" ? true : false;

  return (
    <UnauthorisedLayout chooseAccount={ true } showMobileImg={ showMobileImg } fullMobileTitle={ fullMobileTitle } error={ error } setError={ setErrors }
      pageHeader={
        <h1>{ currentStep === "1" ? t( "create account" ) : t( "one more step" ) }</h1>
      }>
      <div className={ styles.SignUp }>
        <Form
          validators={ {
            email: ( val ) => val !== ''
          } }>
          <Step1 { ...signUpProps }></Step1>
          <Step2 { ...signUpProps }></Step2>
        </Form>
      </div>
    </UnauthorisedLayout>
  )
};

const addUserAction = ( dispatch ) => {
  return {
    addUser: ( user, lng ) => dispatch( addUser( user, lng ) )
  }
}

export default connect( null, addUserAction )( withRouter( withNamespaces( "SignUp" )( SignUp ) ) );
