import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import CustomButton from '../../shared/CustomButton/CustomButton';
import CustomInputField from '../../shared/InputField/CustomInputField';
import AccountImg from '../../../assets/images/Account.png';
import { addPatient } from '../../../redux/actions/userActions';

import styles from './AddPatient.module.scss';

const AddPatient = ( { t, ...props } ) => {


  const handleForm = ( event ) => {
    event.preventDefault();
    const patientInfo = { firstName, lastName, institution, department, chamberNumber }
    props.addPatient( patientInfo ).then( ( res ) => {
      props.history.push( '/agenda/select-patient' );
      // props.path === "/agenda" ? props.history.push('/agenda/select-patient') : props.history.push( '/agenda' )
    } ).catch( error => {
      console.log( error );
      // setErrors( `${error.message}` );
    } );
  };

  const [ firstName, setFirstName ] = useState( "" );
  const [ lastName, setLastName ] = useState( "" );
  const [ institution, setInstitution ] = useState( "" );
  const [ department, setDepartment ] = useState( "" );
  const [ chamberNumber, setChamberNumber ] = useState( "" );

  return (
    <form className={ styles.AddPatient } onSubmit={ event => handleForm( event ) }>
      <h1>{ t( "title" ) }</h1>
      <img src={ AccountImg } alt="" />
      <CustomInputField
        value={ firstName }
        onChange={ e => setFirstName( e.target.value ) }
        name="first-name"
        type="text"
        placeholder={ t( "first name" ) }
        required
      />
      <CustomInputField
        value={ lastName }
        onChange={ e => setLastName( e.target.value ) }
        name="last-name"
        type="text"
        placeholder={ t( "last name" ) }
        required
      />
      <CustomInputField
        value={ institution }
        onChange={ e => setInstitution( e.target.value ) }
        name="healthcare-institution"
        type="text"
        placeholder={ t( "heathcare institution" ) }
        required
      />
      <CustomInputField
        value={ department }
        onChange={ e => setDepartment( e.target.value ) }
        name="department"
        type="text"
        placeholder={ t( "department" ) }
        required
      />
      <CustomInputField
        value={ chamberNumber }
        onChange={ e => setChamberNumber( e.target.value ) }
        name="chamber-number"
        type="number"
        placeholder={ t( "chamber number" ) }
        required
      />

      <CustomButton iconType={ "check" } type="submit">{ t( "submit" ) }</CustomButton>
    </form>
  )
}


const addPatientAction = ( dispatch ) => {
  return {
    addPatient: ( patientInfo ) => dispatch( addPatient( patientInfo ) )
  }
}


export default connect( null, addPatientAction )( withRouter( withNamespaces( "PatientStrings" )( AddPatient ) ) );
