
import SignIn from "../components/SignIn/SignIn";
import GetStarted from '../components/GetStarted/GetStarted';
import SignUp from '../components/SignUp/SignUp';
import PasswordReset from '../components/PasswordReset/PasswordReset';

export default [
  {
    path: "/",
    Component: GetStarted,
    exact: true
  },
  {
    path: "/sign-up",
    Component: SignUp
  },
  {
    path: "/login",
    Component: SignIn
  },
  {
    path: "/password-reset",
    Component: PasswordReset
  }
]
