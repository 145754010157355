import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import styles from './ConfirmDialog.module.scss';

const ConfirmDialog = ( { strings, name, confirmAction, inputs, ...props } ) => {
  const [ open, setOpen ] = React.useState( false );

  const handleClickOpen = ( e ) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen( true );
  };

  const handleClose = ( value ) => {
    setOpen( false );
  };

  const handleClick = ( e ) => {
    confirmAction();
  }

  return (
    <div className={ styles.ConfirmDialog }>

      <div onClick={ handleClickOpen } className="onClick">
        { props.children }
      </div>
      <Dialog
        open={ open }
        onClose={ handleClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ styles.Dialog }
      >
        <DialogTitle id="alert-dialog-title">{ strings[ 'title' ] }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { strings[ 'description' ] } { name }
          </DialogContentText>
        </DialogContent>
        { inputs }
        <DialogActions className={ styles.ButtonsContainer }>
          <Button onClick={ handleClose } color="primary">
            { strings[ 'cancel' ] }
          </Button>
          <Button onClick={ handleClick } color="primary" variant="contained" autoFocus>
            { strings[ 'confirm' ] }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};

export default ConfirmDialog;