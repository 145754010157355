import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './FeedbackDialog.module.scss';
import { withNamespaces } from 'react-i18next';
import CustomInputField from '../../shared/InputField/CustomInputField';

const FeedbackDialog = ( { t, sendFeedback, save, ...props } ) => {
  const [ open, setOpen ] = React.useState( false );
  const [ feedback, setFeedback ] = React.useState( "" );

  const handleClickOpen = ( e ) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen( true );
  };

  const handleClose = () => {
    sendFeedback("");
    save();
    setOpen( false );
  };

  const handleClick = ( e ) => {
    sendFeedback( feedback );
    save();
    setOpen( false );
  }

  return (
    <div className={ styles.FeedbackDialog }>

      <div onClick={ handleClickOpen } className="onClick">
        { props.children }
      </div>
      <Dialog
        open={ open }
        onClose={ handleClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={ styles.Dialog }
      >
        <DialogTitle id="alert-dialog-title">{ t( 'title' ) }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { t( 'description' ) }
          </DialogContentText>
          <CustomInputField
            value={ feedback }
            onChange={ e => setFeedback( e.target.value ) }
            placeholder={ t( 'feedback placeholder' ) }
            required
          ></CustomInputField>
        </DialogContent>
        <DialogActions className={ styles.ButtonsContainer }>
          <Button onClick={ handleClose } color="primary">
            { t( "don't send" ) }
          </Button>
          <Button onClick={ handleClick } color="primary" variant="contained" autoFocus>
            { t( 'send' ) }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};

export default withNamespaces( 'FeedbackDialog' )( FeedbackDialog );
