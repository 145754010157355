import React from 'react';
import styles from './ChooseTimeCards.module.scss';
import CustomCard from '../../shared/CustomCard/CustomCard';
import { withNamespaces } from 'react-i18next';

const ChooseTimeCards = ( { timeSlots, selectedTimeSlotId, setSelectedTimeSlotId, i18n } ) => {
  const onSelect = ( timeSlot ) => {
    setSelectedTimeSlotId( timeSlot.id );
  }
  const getPrettyDate = ( d ) => {
    const date = new Date( d );
    const languages = { en: "en-GB", ge: 'de-DE', de: 'nl-NL' }
    try {
      return new Intl.DateTimeFormat( languages[i18n.languages[0]], {
        weekday: 'long',
        month: "long",
        day: "2-digit"
      } ).format( date )
    }
    catch {
      return new Intl.DateTimeFormat( languages[i18n.languages[0]], { weekday: 'long', month: "long", day: "2-digit" } ).format( new Date() )
    }
  }

  const getCard = ( timeSlot ) => {
    const cardProps = {
      onClick: () => onSelect( timeSlot ),
      key: timeSlot.id,
      title: getPrettyDate( timeSlot.data.date ),
      content: `${timeSlot.data.startTime} to ${timeSlot.data.endTime}`,
      highlight: timeSlot.id === selectedTimeSlotId ? "true" : undefined
    };
    return ( <CustomCard { ...cardProps }></CustomCard> )
  }

  const getCards = () => {
    const timeSlotsCards = [];
    for ( const index in timeSlots ) {
      const timeSlotData = timeSlots[index].data;
      if ( timeSlotData && ( !timeSlotData.bookedBy || timeSlotData.bookedBy === "" ) ) {
        timeSlotsCards.push( getCard( timeSlots[ index ] ) )
      }
    }
    return timeSlotsCards;
  }


  return (
    <div className={ styles.ChooseTimeCards }>
      { getCards() }
    </div>
  )
};

export default withNamespaces()( ChooseTimeCards );
