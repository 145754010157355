import React from 'react';
import gradientImg from "../../../assets/images/gradient.png";
import footerLogo from "../../../assets/images/vtpFooterLogo.png";

import styles from './Footer.module.scss';

const Footer = () => (
  <div className={styles.Footer}>
    <img src={gradientImg} alt="" />
    <img className={styles.vtpLogo} src={footerLogo} alt="" />
  </div>
);

export default Footer;
