import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./utils/theme"
import store from "./redux/store";
import Router from "./routes/router";

import './App.css';



const rootElement = document.getElementById( "root" );
ReactDOM.render(
  <Provider store={ store }>

  </Provider>,
  rootElement
);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={ theme }>
        <Router></Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
