import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { confirmMail } from '../../redux/actions/authActions';
import styles from './ConfirmMail.module.scss';
import LinkButton from '../shared/LinkButton/LinkButton';

const ConfirmMail = ( { currentUser, location, confirmMail, t } ) => {

  const userId = new URLSearchParams( location.search ).get( 'id' );
  if ( currentUser && !currentUser[ 'confirmedMail' ] ) {
    confirmMail( userId );
  }
  return (
    <div className={ styles.ConfirmMail }>
      {/* <h1>{ currentUser[ 'confirmedMail' ] ? t ('mail aready confirmed') : t ('mail confirmed') }</h1> */ }
      <h1>{ t( 'mail confirmed' ) }</h1>
      <LinkButton to="/agenda" fullWidth>{ t( "go to main page" ) }</LinkButton>
    </div>
  )
};


const mapStateToProps = ( state ) => {
  return {
    currentUser: state.auth.currentUserData
  }
}

const confirmMailActions = ( dispatch ) => {
  return {
    confirmMail: ( userId ) => dispatch( confirmMail( userId ) )
  }
}

export default connect( mapStateToProps, confirmMailActions )( withRouter( withNamespaces( "ConfirmMail" )( ConfirmMail ) ) );
