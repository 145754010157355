import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';

import { deleteVisitor } from "../../../redux/actions/visitorsActions";
import CustomCard from '../../shared/CustomCard/CustomCard';
import styles from './VisitorsCards.module.scss';

const VisitorsCards = ( { visitors, timeSlots, t, deleteVisitor, i18n } ) => {

  const getPrettyDate = ( d ) => {
    const date = new Date( d );
    const languages = { en: "en-GB", ge: 'de-DE', de: 'nl-NL' }
    try {
      return new Intl.DateTimeFormat( languages[ i18n.languages[ 0 ].slice(0,2) ], {
        month: "long",
        day: "2-digit"
      } ).format( date )
    }
    catch {
      return new Intl.DateTimeFormat( languages[ i18n.languages[ 0 ].slice(0,2) ], { weekday: 'long', month: "long", day: "2-digit" } ).format( new Date() )
    }
  }

  const getAction = ( timeSlot, visitor ) => {
    const userId = localStorage.getItem( 'userId' ),
      visitorId = visitor.id;
    return timeSlot ?
      <div className={ styles.acceptedInvite }>
        <MoreHorizIcon></MoreHorizIcon>
        <span>{ getPrettyDate( timeSlot.data.date ) }</span>
        <span>{ timeSlot.data.startTime }</span>
        <DeleteIcon onClick={ () => deleteVisitor( userId, visitorId, timeSlot.id ) } className={ styles.deleteIcon }></DeleteIcon>
      </div>
      :
      <div className={ styles.invitedText }>{ t( 'invited' ) }<DeleteIcon onClick={ () => deleteVisitor( userId, visitorId ) } className={ styles.deleteIcon }></DeleteIcon></div>
  }

  const getCard = ( visitor ) => {
    const visit = timeSlots.length ? timeSlots.find( ( timeSlot ) => timeSlot.data.bookedBy === visitor.id ) : null,
      cardProps = {
        key: visitor.id,
        title: visitor.data.name,
        content: visitor.data.email,
        clickable: false,
        action: getAction( visit, visitor ),
      };
    return ( <CustomCard { ...cardProps }></CustomCard> )
  }

  const getVisitorsCards = () => {
    const visitorsCards = [];
    for ( const visitorIndex in visitors ) {
      visitorsCards.push( getCard( visitors[ visitorIndex ] ) )
    }
    return visitorsCards;
  }
  return (
    <div className={ styles.VisitorsCards }>
      { getVisitorsCards() }
    </div>
  )
};

const deleteVisitorAction = ( dispatch ) => {
  return {
    deleteVisitor: ( userId, visitorId, timeslotId ) => dispatch( deleteVisitor( userId, visitorId, timeslotId ) )
  }
}

export default connect( null, deleteVisitorAction )( withNamespaces( "VisitorsCards" )( VisitorsCards ) );
