import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import LinkButton from '../shared/LinkButton/LinkButton';
import AuthPagesTitle from '../shared/AuthPagesTitle/AuthPagesTitle';
import DiaryNotesCards from './DiaryBookCards/DiaryBookCards'

import styles from './DiaryBook.module.scss';
import { withRouter } from 'react-router';


const DiaryBook = ( { diaryNotes, currentUser, history, path, t } ) => {
  const currentUserData = useSelector( state => state.auth.currentUserData ),
    userName = currentUserData ? ( currentUserData.userRole === "patient" ? "Me" : `${currentUserData.firstName} ${currentUserData.lastName}` ) : "";

  if ( currentUserData.userRole !== "patient" && path !== "/agenda/select-patient" && ( userName === "undefined undefined" ) ) {
    history.push( "/agenda/select-patient" )
  }
  const buttonPropsStyles = {
    'backgroundColor': "#F3ECEB",
    'borderRadius': '12px',
    'color': '#C7A2A0',
    'height': '50px',
  },
    viewOnly = !!Object.keys( currentUser ).length;
  return (
    <div className={ styles.DiaryBook }>
      { viewOnly ? <AuthPagesTitle title={ t( 'title' ) }></AuthPagesTitle> : <React.Fragment></React.Fragment> }

      {
        !diaryNotes.isLoading ? (
          diaryNotes.diaryNotes.length ?
            (
              <DiaryNotesCards diaryNotes={ diaryNotes.diaryNotes } viewOnly={ viewOnly }></DiaryNotesCards>
            )
            :
            (
              <div className={ styles.noDiaryNotes }>
                {/* <img src={ noTimeSlotsImage } alt="" /> */ }
                <h2>{ t( 'no diary notes' ) }</h2>
                <span>{ t( 'add your first' ) }</span>
              </div>
            ) )
          :
          ( <React.Fragment></React.Fragment> )
      }
      {
        viewOnly ?
          <LinkButton to="/diary-book/add" buttonProps={ { variant: "contained", style: buttonPropsStyles } }>{ t( 'add new post' ) }</LinkButton>
          :
          <React.Fragment></React.Fragment>
      }
    </div>
  )
};

const mapStateToProps = ( state ) => {
  return {
    diaryNotes: state.diaryNotes,
    currentUser: state.auth.currentUserData
  }
}

export default connect( mapStateToProps )( withNamespaces( "DiaryBook" )( withRouter( DiaryBook ) ) );
