import React from "react";
import ReactDOM from "react-dom";
import firebase from "@firebase/app";
import "@firebase/auth";
import '@firebase/database';
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import App from "./App";
import { environment } from "./constants/configurations/firebaseConfig"
import store from "./redux/store"
import "./index.css";

import { Router } from "react-router-dom";
import history from "./utils/historyService"

import * as serviceWorker from './serviceWorker';
import './i18n/i18n';

const rrfConfig = {
  userProfile: "users",
};

firebase.initializeApp( environment.firebase );


const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <ReactReduxFirebaseProvider { ...rrfProps }>
        <Router history={ history }>
          <App />
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById( "root" )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if ( process.env.NODE_ENV === 'production' ||  process.env.NODE_ENV === "prod" ) {
  serviceWorker.unregister();
}
else {
  console.log( process.env.NODE_ENV );
  serviceWorker.register();
}
