import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { TextField, withStyles, Divider } from '@material-ui/core';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';

import { addNewTimeSlot, editTimeSlot, deleteTimeSlot } from '../../../redux/actions/timeSlotsActions'
import CustomInputField from '../../shared/InputField/CustomInputField';
import CustomButton from '../../shared/CustomButton/CustomButton';
import CustomSnackbar, { severities } from '../../shared/CustomSnackbar/CustomSnackbar';
import ConfirmDialog from '../../shared/ConfirmDialog/ConfirmDialog';

import styles from './AddTimeSlot.module.scss';

const TimeField = withStyles( {
  root: {
    "flex-grow": 1,
    '& .MuiInputBase-root': {
      'font-weight': 500,
      'text-align': 'center',
      'color': '#2C0915',
      "padding": "5px",
    },
    '& label': {
      'font-size': '16px',
      'color': '#C7A2A0'
    }
  },
} )( TextField );


const AddTimeSlot = ( { history, location, timeSlots, t, i18n, ...props } ) => {

  const getFormatedDate = ( date ) => `${date.getFullYear()}-${( "0" + ( date.getMonth() + 1 ) ).slice( -2 )}-${( "0" + date.getDate() ).slice( -2 )}`;
  let isNew = true, timeSlot = { id: "", data: { bookedBy: "" } },
    minDate = getFormatedDate( new Date() ), date = getFormatedDate( new Date() ), start = "13:00", end = "14:00";
  const id = new URLSearchParams( location.search ).get( 'id' );

  if ( id && timeSlots.timeSlots.length ) {
    timeSlot = timeSlots.timeSlots.find( timeSlot => timeSlot.id === id );
    date = getFormatedDate( new Date( timeSlot.data.date ) );
    start = timeSlot.data.startTime;
    end = timeSlot.data.endTime;
    isNew = false;
  }

  const [ day, setDay ] = useState( date ),
    [ startTime, setStartTime ] = useState( start ),
    [ endTime, setEndTime ] = useState( end ),
    [ message, setMessage ] = useState( { severity: severities.info, message: "" } );

  useEffect( () => {
    setDay( date );
    setStartTime( start );
    setEndTime( end )
  }, [ date, end, start ] );

  const setField = ( e, callback ) => {
    return callback( e.target.value );
  }
  const deleteTimeSlot = () => {
    const userId = localStorage.getItem( 'userId' );
    props.deleteTimeSlot( timeSlot.id, userId, i18n.languages[ 0 ].slice(0,2), timeSlot.data.bookedBy );
    history.push( '/agenda' );
  }

  const save = () => {
    if ( new Date() > new Date( `${day}T${startTime}` ) ) {
      setMessage( { severity: severities.error, message: t( "old date error" ) } )
      return false;
    }
    else if ( startTime >= endTime ) {
      setMessage( { severity: severities.error, message: t( "end time shold be after" ) } )
      return false;
    }
    timeSlot.data.date = day;
    timeSlot.data.startTime = startTime;
    timeSlot.data.endTime = endTime;
    const userId = localStorage.getItem( 'userId' );
    isNew ? props.addNewTimeSlot( timeSlot.data, userId ) : props.editTimeSlot( timeSlot, userId );
    return true;
  }

  const onSubmit = () => {
    if ( save() ) history.push( '/agenda' );
  }

  const saveAndAddMore = () => {
    save();
    setDay( date );
    setStartTime( start );
    setEndTime( end );
    setMessage( { severity: severities.success, message: t( "timslot added" ) } )
  }

  const resetMessage = () => {
    setMessage( { severity: severities.info, message: "" } );
  }

  const getConfirmDialog = () => {
    return {
      title: t( 'title' ),
      description: t( 'description' ),
      cancel: t( 'cancel' ),
      confirm: t( 'confirm' )
    }
  }

  const languages = { en: "en-GB", ge: 'de-DE', de: 'nl-NL' }

  return (
    <div className={ styles.AddTimeSlot }>
      <h1>{ !id ? t( "title add" ) : t( "title edit" ) }</h1>
      <form>
        <CustomInputField
          type="date"
          format="yyyy-MM-dd"
          label={ t( "select day" ) }
          value={ day }
          onChange={ e => setField( e, setDay ) }
          InputProps={ {
            inputProps: { min: minDate }
          } }
          InputLabelProps={ {
            shrink: true,
          } }
          locales={ languages[ i18n.languages[ 0 ] ] }
          required
        />
        <div className={ styles.timePickersContainer }>
          <TimeField
            id="time"
            label={ t( "from" ) }
            type="time"
            InputLabelProps={ {
              shrink: true,
            } }
            InputProps={ {
              classes: {
                input: styles.inputProps,
              }
            } }
            value={ startTime }
            onChange={ e => setField( e, setStartTime ) }
            required
          />
          <Divider orientation="vertical" flexItem />
          <TimeField
            id="time"
            label={ t( "to" ) }
            type="time"
            InputLabelProps={ {
              shrink: true,
            } }
            InputProps={ {
              inputProps: {
                min: startTime
              },
              classes: {
                input: styles.inputProps,
              }
            } }
            value={ endTime }
            onChange={ e => setField( e, setEndTime ) }

            min={ startTime }
            required
          />
        </div>
        { isNew ?
          (
            <div className={ styles.buttonsContainer }>
              <CustomButton onClick={ onSubmit } iconType={ "sum" }>{ t( "add sumbit text" ) }</CustomButton>
              <CustomButton onClick={ saveAndAddMore } iconType={ "sum" }>{ t( "save and add more" ) }</CustomButton>
            </div>
          )
          :
          (
            <div className={ styles.buttonsContainer }>
              <CustomButton onClick={ onSubmit } iconType={ "check" }>{ t( "edit sumbit text" ) }</CustomButton>

              <ConfirmDialog confirmAction={ deleteTimeSlot } strings={ getConfirmDialog() } name={ "" }>
                <CustomButton iconType={ "delete" }>{ t( "delete" ) }</CustomButton>
              </ConfirmDialog>
            </div>
          )
        }
        <CustomSnackbar severity={ message.severity } closeSnackBar={ resetMessage } >{ message.message }</CustomSnackbar>
      </form>
    </div>
  )
};

const mapStateToProps = ( state ) => {
  return {
    timeSlots: state.timeSlots
  }
}

const addNewTimeSlotAction = ( dispatch ) => {
  return {
    addNewTimeSlot: ( timeSlotData, id ) => dispatch( addNewTimeSlot( timeSlotData, id ) ),
    editTimeSlot: ( timeSlotData, id ) => dispatch( editTimeSlot( timeSlotData, id ) ),
    deleteTimeSlot: ( timeSlotId, id, lng, bookedBy ) => dispatch( deleteTimeSlot( timeSlotId, id, lng, bookedBy ) )
  }
}

export default connect( mapStateToProps, addNewTimeSlotAction )( withRouter( withNamespaces( "AddTimeSlot" )( AddTimeSlot ) ) );
