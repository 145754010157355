import { INVITE_VISITOR, FETCH_VISITORS, LOADING } from "../actionTypes";
import axios from 'axios';

const inviteVisitor = ( visitorData, originUrl, lng ) => {
  return ( dispatch, getState, getFirebase ) => {
    const firebase = getFirebase(),
      currentUser = getState().auth.currentUserData,
      patientId = localStorage.getItem( 'userId' ),
      userId = firebase.auth().currentUser.uid,
      userName = `${currentUser.firstName} ${currentUser.lastName}`;
    return firebase.ref( `visitors/${patientId}` ).push( visitorData ).then( ( response ) => {
      const visitorId = response.path.pieces_[ 2 ];
      return axios.get( `https://us-central1-visit-the-patient.cloudfunctions.net/invite?dest=${visitorData.email}&patientName=${userName}&link=${originUrl}/visit?patientId=${patientId}%26visitorId=${visitorId}%26userId=${userId}%26lng=${lng}` )
        // return axios.get( `http://localhost:5001/visit-the-patient/us-central1/invite?dest=${visitorData.email}&patientName=${userName}&link=${originUrl}/visit?patientId=${patientId}%26visitorId=${visitorId}%26userId=${userId}%26lng=${lng}` )
        .then( ( resp ) => {
          dispatch( { type: INVITE_VISITOR, payload: resp } );
        } ).catch( e => {
          console.log( e );
        } )
    } );
  }
}

const fetchVisitors = ( userId ) => {
  return ( dispatch, _getState, getFirebase ) => {
    dispatch( { type: LOADING, payload: false } )
    const firebase = getFirebase();
    userId = userId ? userId : firebase.auth().currentUser.uid;
    firebase.ref( `visitors/${userId}` ).on( 'value', ( snapshot ) => {
      const data = snapshot.val();

      const visitors = [];
      for ( const propertyKey in data ) {
        visitors.push(
          {
            id: propertyKey,
            data: data[ propertyKey ]
          }
        );
      }
      dispatch( { type: FETCH_VISITORS, payload: visitors } );
    } );
  }
}

const deleteVisitor = ( userId, visitorId, timeSlotId ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    return firebase.ref( `visitors/${userId}/${visitorId}` ).remove().then( () => {
      if ( timeSlotId ) {
        return firebase.ref( `timeSlots/${userId}/${timeSlotId}` ).update( { bookedBy: "" } )
      }
    } )
  }
}

const sendFeedback = ( visitor, userId, msg = "", lng ) => {
  return ( _dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    return firebase.ref( `visitors/${userId}/${visitor.id}` ).update( { feedbackSent: true }, () => {
      if ( msg ) {
        return axios.get( `https://us-central1-visit-the-patient.cloudfunctions.net/feedback?visitorName=${visitor.data.name}&message=${msg}&lng=${lng}` )
        // return axios.get( `http://localhost:5001/visit-the-patient/us-central1/feedback?visitorName=${visitor.data.name}&message=${msg}&lng=${lng}` )
      }
      return Promise.resolve();
    } )
  }
}

export { inviteVisitor, fetchVisitors, sendFeedback, deleteVisitor }
