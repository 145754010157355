import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { withNamespaces } from 'react-i18next';
import BorderColorSharpIcon from '@material-ui/icons/BorderColorSharp';

import PatientsCards from './PatientsCards/PatientsCards';
import CustomButton from '../shared/CustomButton/CustomButton';
import styles from './Patients.module.scss';

const Patients = ( { patients, history, t } ) => {
  const [ cardsView, setcardsView ] = useState( !history.location.pathname.includes( '/edit' ) );
  const editCards = () => {
    history.push( '/agenda/select-patient/edit' );
    setcardsView( false );
  },
    saveCards = () => {
      history.push( '/agenda/select-patient' );
      setcardsView( true );
    }
  return (
    <div className={ styles.Patients }>
      {
        cardsView ?
          (
            <div className={ styles.Title }>
              <h1>{ t( 'title' ) }</h1>
              <BorderColorSharpIcon onClick={ editCards }></BorderColorSharpIcon>
            </div>
          )
          :
          (
            <div className={ styles.Title }>
              <h1>{ t( 'edit title' ) }</h1>
              <CustomButton className={ styles.desktopButton } variant={ "contained" } color={ "secondary" } onClick={ saveCards } fullWidth={ false }>
                <div className={ styles.SaveButton }>{ t( 'done' ) }</div>
              </CustomButton>
            </div>
          )
      }
      {
        isLoaded( patients ) ?
          (
            <PatientsCards patients={ patients } cardsView={ cardsView }></PatientsCards>
          )
          :
          (
            <React.Fragment></React.Fragment>
          )
      }

      <CustomButton onClick={ () => { history.push( "/add-patient" ) } } iconType={ "sum" }>{ t( 'add patient' ) }</CustomButton>
    </div>
  )
};


const mapStateToProps = ( state ) => {
  return {
    patients: state.auth.patients
  }
}

export default connect( mapStateToProps )( withRouter( withNamespaces( "Patients" )( Patients ) ) );
