import ChooseTime from "../components/ChooseTime/ChooseTime";
import ConfirmMail from "../components/ConfirmMail/ConfirmMail";
import CancelAppointment from "../components/CancelAppointment/CancelAppointment";
import DiaryBook from "../components/DiaryBook/DiaryBook";

export default [
  {
    path: "/visit",
    Component: ChooseTime
  },
  {
    path: "/verify",
    Component: ConfirmMail
  },
  {
    path: "/cancel-appointment",
    Component: CancelAppointment
  },
  {
    path: "/view-diary-notes",
    Component: DiaryBook
  }
]