import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';

import ChooseTimeCards from './ChooseTimeCards/ChooseTimeCards';
import CustomButton from '../shared/CustomButton/CustomButton';
import CustomSnackbar from '../shared/CustomSnackbar/CustomSnackbar';
import VTPImage from "../../assets/images/vtpLogo.png";
import UserIconButton from '../shared/UserIconButton/UserIconButton';
import noTimeSlotsImage from '../../assets/images/noTimeSlotsImage.png';
import Footer from '../shared/Footer/Footer';
import { scheduleAppointment } from '../../redux/actions/timeSlotsActions';
import styles from './ChooseTime.module.scss';
import FeedbackDialog from './FeedbackDialog/FeedbackDialog';
import { sendFeedback } from '../../redux/actions/visitorsActions';

const ChooseTime = ( { location, timeSlots, currentUser, visitors, scheduleAppointment, sendFeedback, t, i18n } ) => {
  const [ selectedTimeSlotId, setSelectedTimeSlotId ] = useState( "" );
  const [ error, setError ] = useState( "" );
  const [ success, setSuccess ] = useState( "" );
  const patientId = new URLSearchParams( location.search ).get( 'patientId' ),
    visitorId = new URLSearchParams( location.search ).get( 'visitorId' ),
    userId = new URLSearchParams( location.search ).get( 'userId' ),
    visitor = visitors.visitorsData && visitors.visitorsData.length ? visitors.visitorsData.find( visitor => visitor.id === visitorId ) : {};
  const onSave = () => {
    if ( !selectedTimeSlotId ) {
      setError( t( "appointment not selected" ) );;
    }
    else {
      scheduleAppointment( userId, patientId, visitorId, selectedTimeSlotId, `${currentUser.firstName} ${currentUser.lastName}`, i18n.languages[ 0 ].slice( 0, 2 ) );
      setSuccess( t( "appointment added" ) )
    }
  }

  const getStortedTimeslot = ( timeSlots ) => {
    return timeSlots.sort( ( timeslot1, timeslot2 ) => {
      if ( timeslot1.data.date < timeslot2.data.date ) {
        return -1
      }
      return 1
    } )
  }

  const availableTimeSlots = () => getStortedTimeslot( timeSlots.timeSlots.filter( timeSlot => !timeSlot.data.bookedBy || timeSlot.data.bookedBy === "" ) )

  const cardsProps = {
    setSelectedTimeSlotId,
    selectedTimeSlotId,
    timeSlots: availableTimeSlots(),
  }

  return (
    <div className={ styles.ChooseTime }>
      <div className={ styles.Title }>
        <img className={ styles.MobileLogo } src={ VTPImage } alt="" />
        <UserIconButton size="big" userData={ currentUser }></UserIconButton>
        { currentUser.firstName && <h1>{ t( 'title' ) } { currentUser.firstName } { currentUser.lastName } </h1> }
        { currentUser.institution &&
          <span className={ styles.user }>{ currentUser.institution },</span>
        }
        { currentUser.department &&
          <span className={ styles.user }>{ currentUser.department }, { t( 'chamber' ) } { currentUser.chamberNumber }</span>
        }
      </div>
      <div className={ styles.Content }>
        {
          isLoaded( timeSlots ) && !timeSlots.isLoading && visitor ? (
            availableTimeSlots().length ?
              (
                <React.Fragment>
                  <ChooseTimeCards { ...cardsProps }></ChooseTimeCards>
                  {
                    visitor && visitor.data && visitor.data.feedbackSent ?
                      <CustomButton onClick={ onSave } iconType="check">{ t( 'save' ) }</CustomButton>
                      :
                      <FeedbackDialog save={ onSave } sendFeedback={ ( msg ) => { sendFeedback( visitor, patientId, msg, i18n.languages[ 0 ].slice( 0, 2 ) ) } }>
                        <CustomButton iconType="check">{ t( 'save' ) }</CustomButton>
                      </FeedbackDialog>
                  }
                </React.Fragment>
              ) :
              (
                <div className={ styles.noTimeSlots }>
                  <img src={ noTimeSlotsImage } alt="" />
                  <h2>{ t( 'no time slots' ) }</h2>
                  <span>
                    { t( 'contact' ) } { currentUser.firstName } { currentUser.lastName } { currentUser.email && ( `${t( 'at' )} ${currentUser.email}` ) } { t( 'for details' ) }
                  </span>
                </div>
              )
          ) :
            ( <div style={ { display: "flex", justifyContent: "center" } }> {t( 'Something went wrong' ) } { currentUser.firstName } { currentUser.lastName } </div> )
        }
      </div>
      <Footer></Footer>

      { error && <CustomSnackbar severity={ "error" } closeSnackBar={ () => setError( "" ) } >{ error }</CustomSnackbar> }
      { success && <CustomSnackbar severity={ "success" } closeSnackBar={ () => setSuccess( "" ) } >{ success }</CustomSnackbar> }
    </div>
  )
};

const chooseTimeActions = ( dispatch ) => {
  return {
    scheduleAppointment: ( userId, patientId, visitorId, timeSlotId, patientName, lng ) => dispatch( scheduleAppointment( userId, patientId, visitorId, timeSlotId, patientName, lng ) ),
    sendFeedback: ( visitor, patientId, msg, lng ) => dispatch( sendFeedback( visitor, patientId, msg, lng ) )
  }
}


const mapStateToProps = ( state ) => {
  return {
    timeSlots: state.timeSlots,
    currentUser: state.auth.currentUserData,
    visitors: state.visitors
  }
}

export default connect( mapStateToProps, chooseTimeActions )( withRouter( withNamespaces( "ChooseTime" )( ChooseTime ) ) );
