import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector, connect } from "react-redux";

const UnauthorisedRoute = ( { children, currentUser, ...remainingProps } ) => {
  const auth = useSelector( state => state.firebase.auth );
  return (
    <Route
      { ...remainingProps }
      render={ ( { location } ) =>
        isLoaded( auth ) && isEmpty( auth.uid ) ? (
          children
        ) :
          isLoaded( auth ) && !auth.isLoading && !isEmpty( auth.uid ) ? (
            <Redirect
              to={ {
                pathname: "/agenda",
                state: { from: location },
              } }
            />
          ) :
            (
              <React.Fragment></React.Fragment>
            )
      }
    />
  );
};


const mapStateToProps = ( state ) => {
  return {
    currentUser: state.auth.currentUserData
  }
}


export default connect( mapStateToProps )( UnauthorisedRoute );
