import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import styles from './GetStarted.module.scss';
import LinkButton from '../shared/LinkButton/LinkButton';
import UnauthorisedLayout from '../shared/UnauthorisedLayout/UnauthorisedLayout';
import VTPImage from "../../assets/images/vtpLogo.png";
import CustomSnackbar, { severities } from '../shared/CustomSnackbar/CustomSnackbar';
import { connect } from 'react-redux';
import { resetUserState } from '../../redux/actions/authActions';

const GetStarted = ( { t, resetUserState, auth } ) => {
  return (
    <UnauthorisedLayout pageHeader={
      <div>
        <h1 className={ styles.desktopTitle }>{ t( 'title' ) }</h1>
        <img className={ styles.mobileTitle } src={ VTPImage } alt="" />
      </div> }>
      <div className={ styles.GetStarted }>
        <div className={ styles.textContainer }>
          <div className={ styles.text }>{ t( 'text' ) }</div>
        </div>
        <div className={ styles.buttonsContainer }>
          <LinkButton onClick={ resetUserState } className={ styles.signUpButton } to="/sign-up">{ t( 'get started' ) }</LinkButton>
          <Link onClick={ resetUserState } className={ styles.signInLink } to="/login"><div >{ t( 'login' ) }</div></Link>
        </div>
      </div>
      {
        auth.currentAuthState === "deleted" &&
        <CustomSnackbar severity={ severities.success } closeSnackBar={ resetUserState }>{ t( 'user deleted' ) }</CustomSnackbar>
      }
    </UnauthorisedLayout>
  )
};

const mapStateToProps = ( state ) => {
  return {
    auth: state.auth
  }
}

const getStartedAction = ( dispatch ) => {
  return {
    resetUserState: () => dispatch( resetUserState() )
  }
}

export default connect( mapStateToProps, getStartedAction )( withNamespaces( "GetStarted" )( GetStarted ) );
