export const environment = {
  firebase: {
    apiKey: "AIzaSyB_BuESR6--rWUlOcty8UgmmyI4WQYg3YQ",
    authDomain: "visit-the-patient.firebaseapp.com",
    databaseURL: "https://visit-the-patient.firebaseio.com",
    projectId: "visit-the-patient",
    storageBucket: "visit-the-patient.appspot.com",
    messagingSenderId: "620083189281",
    appId: "1:620083189281:web:2d7654356b111ed490b560",
    measurementId: "G-LK0X05V8QS"
  }
};