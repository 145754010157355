import EditUserDetails from '../components/MyProfile/EditUserDetails/EditUserDetails';
import AddPatient from '../components/Patients/AddPatient/AddPatient';
import Agenda from '../components/Agenda/Agenda';
import MyProfile from '../components/MyProfile/MyProfile';
import AddTimeSlot from '../components/Agenda/AddTimeSlot/AddTimeSlot';
import Patients from '../components/Patients/Patients';
import Visitors from '../components/Visitors/Visitors';
import InviteVisitor from '../components/Visitors/InviteVisitor/InviteVisitor';
import DiaryBook from '../components/DiaryBook/DiaryBook';
import DiaryBookAdd from '../components/DiaryBook/DiaryBookAdd/DiaryBookAdd';

export default [
  {
    path: "/add-patient",
    Component: AddPatient,
    noMenu: true
  },
  {
    path: "/add-account-details",
    Component: EditUserDetails,
    noMenu: true
  },
  {
    path: "/agenda",
    components: [
      {
        path: "/agenda",
        Component: Agenda,
        exact: true,
      },
      {
        path: "/agenda/add",
        Component: AddTimeSlot
      },
      {
        path: "/agenda/edit",
        Component: AddTimeSlot
      },
      {
        path: "/agenda/select-patient",
        Component: Patients
      }
    ]
  },
  {
    path: "/visitors",
    components: [
      {
        path: "/visitors",
        Component: Visitors,
        exact: true
      },
      {
        path: "/visitors/invite",
        Component: InviteVisitor
      }
    ]
  },
  {
    path: "/diary-book",
    components: [
      {
        path: "/diary-book",
        Component: DiaryBook,
        exact: true
      },
      {
        path: "/diary-book/add",
        Component: DiaryBookAdd
      },
      {
        path: "/diary-book/edit",
        Component: DiaryBookAdd
      }
    ]
  },
  {
    path: "/profile",
    components: [
      {
        path: "/profile",
        Component: MyProfile,
        exact: true
      },
      {
        path: "/profile/edit-account-details",
        Component: EditUserDetails
      }
    ]
  },
  // {
  //   redirect: "/agenda"
  // } 
]
