import { ADD_DIARY_NOTE, EDIT_DIARY_NOTE, FETCH_DIARY_NOTES, DIARY_NOTES_LOADING } from "../actionTypes";

const initState = {
  isLoading: true,
  diaryNotes: []
}

const timeSlotsReducer = ( state = initState, action ) => {
  switch ( action.type ) {
    case FETCH_DIARY_NOTES:
      return {
        diaryNotes: action.payload,
        isLoading: false,
      };

    case ADD_DIARY_NOTE:
      return {
        ...state,
        isLoading: false,
      };

    case EDIT_DIARY_NOTE:
      return {
        ...state,
        isLoading: false,
      };

    case DIARY_NOTES_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    default:
      return state;
  }
}

export default timeSlotsReducer;
