import React, { useState } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { connect } from "react-redux";

import AuthorisedLayout from "../components/shared/AuthorisedLayout/AuthorisedLayout";

import { setCurrentUser, fetchPatients } from '../redux/actions/userActions';
import { fetchVisitors } from "../redux/actions/visitorsActions";
import { fetchDiaryNotes } from "../redux/actions/diaryNotes";
import { fetchTimeSlots } from '../redux/actions/timeSlotsActions';

const PrivateRoute = ( { children, noMenu, timeSlots, firebaseAuth, path, auth, ...props } ) => {
  const { setCurrentUser, fetchTimeSlots, fetchPatients, fetchVisitors, fetchDiaryNotes } = props,
    { currentUserData, patients, currentAuthState } = auth,
    id = localStorage.getItem( 'userId' ) || new URLSearchParams( props.location.search ).get( 'userId' );

  const [ loaded, setLoaded ] = useState( false );

  React.useEffect( () => {
    setCurrentUser();
    if ( isLoaded( firebaseAuth ) && !isEmpty( firebaseAuth ) && currentAuthState === "normal" ) {
      id && id !== 'undefined' ? setCurrentUser( id ) : setCurrentUser();
      fetchPatients( id );
      switch ( path ) {
        case '/agenda':
          fetchTimeSlots( id );
          fetchVisitors( id );
          fetchPatients();
          break;
        case '/visitors':
          fetchTimeSlots( id );
          fetchVisitors( id );
          break;
        case '/diary-book':
          fetchDiaryNotes( id );
          break;
        default:
          break;
      }
    }
    if ( !auth.isLoading ) {
      setLoaded( true );
    }
  }, [ path, firebaseAuth, id, fetchTimeSlots, fetchVisitors, fetchPatients, fetchDiaryNotes, setCurrentUser, currentAuthState, auth.isLoading ] );

  const hasData = () => !!( currentUserData && currentUserData.firstName && currentUserData.institution ) || path === "/add-account-details" || path === "/add-patient" || path === "/agenda/select-patient" || patients.length > 0;
  const isLoadedData = () => isLoaded( currentUserData ) && ( path === '/agenda' ? isLoaded( patients ) : true ) && ( loaded || path === "/agenda" );
  const isUserSelected = () => ( (currentUserData && currentUserData.userRole === 'patient') || ( props.location.pathname === '/agenda/select-patient' && currentUserData.userRole === 'caregiver' ) || ( id && id !== 'undefined' && id !== undefined ) );
  return (
    <Route
      { ...props }
      render={ ( { location } ) =>
        isLoaded( firebaseAuth ) && !isEmpty( firebaseAuth ) ? // && (currentAuthState === "normal" || currentAuthState === "deleted")
          (
            isLoadedData() && currentAuthState !== "deleting" && loaded ?
              (
                hasData() ?
                  (
                    isUserSelected() ?
                      (
                        noMenu ?
                          <React.Fragment>{ children }</React.Fragment>
                          :
                          <AuthorisedLayout> { children } </AuthorisedLayout>
                      )
                      :
                      (
                        currentUserData.userRole === "caregiver" ?
                          <Redirect to={ { pathname: "/agenda/select-patient" } } />
                          :
                          <Redirect to={ { pathname: "/agenda" } } />
                      )
                  )
                  :
                  (
                    currentUserData.userRole === "patient" ?
                      <Redirect to={ { pathname: "/add-account-details" } } />
                      :
                      <Redirect to={ { pathname: "/add-patient" } } />
                  )
              )
              :
              <React.Fragment></React.Fragment>
          ) :
          isLoaded( firebaseAuth ) ? (
            <Redirect
              to={ {
                pathname: "/"
              } }
            />
          ) :
            (
              <React.Fragment></React.Fragment>
            )
      }
    ></Route >
  )
};

const mapStateToProps = ( state ) => {
  return {
    firebaseAuth: state.firebase.auth,
    auth: state.auth,
    currentUserData: state.auth.currentUserData,
    patients: state.auth.patients,
    timeSlots: state.timeSlots
  }
}

const privateRouteActions = ( dispatch ) => {
  return {
    setCurrentUser: ( id ) => dispatch( setCurrentUser( id ) ),
    fetchTimeSlots: ( id ) => dispatch( fetchTimeSlots( id ) ),
    fetchPatients: () => dispatch( fetchPatients() ),
    fetchVisitors: ( id ) => dispatch( fetchVisitors( id ) ),
    fetchDiaryNotes: ( id ) => dispatch( fetchDiaryNotes( id ) )
  }
}

export default connect( mapStateToProps, privateRouteActions )( withRouter( PrivateRoute ) );
