import {combineReducers} from "redux";
import {firebaseReducer} from "react-redux-firebase";
import authReducer from "./auth";
import diaryNotesReducer from "./diaryNotes";
import timeSlotsReducer from "./timeSlots";
import visitorsReducer from "./visitors";
// import {firestoreReducer} from "redux-firestore";

export const rootReducer = combineReducers({
    firebase: firebaseReducer,
    auth: authReducer,
    diaryNotes: diaryNotesReducer,
    timeSlots: timeSlotsReducer,
    visitors: visitorsReducer
});
