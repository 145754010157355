import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { withNamespaces } from 'react-i18next';

import CustomButton from '../shared/CustomButton/CustomButton';
import AuthPagesTitle from '../shared/AuthPagesTitle/AuthPagesTitle';
import VisitorsCards from './VisitorsCards/VisitorsCards'

import styles from './Visitors.module.scss';

const Visitors = ( { history, visitors, timeSlots, t } ) => (
  <div className={ styles.Visitors }>
    <AuthPagesTitle title={ t( 'title' ) }></AuthPagesTitle>
    {
      isLoaded( visitors ) && !visitors.isLoading ? (
        visitors.visitorsData.length ?
          (
            <VisitorsCards visitors={ visitors.visitorsData } timeSlots={ timeSlots.timeSlots }></VisitorsCards>
          ) :
          (
            <div className={ styles.noVisitors }>
              {/* <img src={ noTimeSlotsImage } alt="" /> */ }
              <h2>{ t( 'noVisitors' ) }</h2>
              <span>{ t( 'addYourFirst' ) }</span>
            </div>
          ) )
        :
        ( <React.Fragment></React.Fragment> )

    }
    <CustomButton iconType="invite" onClick={ () => { history.push( "/visitors/invite" ) } } >{ t( "invite people" ) }</CustomButton>
  </div>
);


const mapStateToProps = ( state ) => {
  return {
    visitors: state.visitors,
    timeSlots: state.timeSlots
  }
}

export default connect( mapStateToProps )( withRouter( withNamespaces("Visitors")(Visitors) ) );
