import { FETCH_DIARY_NOTES, ADD_DIARY_NOTE, EDIT_DIARY_NOTE, DIARY_NOTES_LOADING } from "../actionTypes";
import axios from 'axios';

const fetchDiaryNotes = ( userId ) => {
  return ( dispatch, _getState, getFirebase ) => {
    dispatch( { type: DIARY_NOTES_LOADING, payload: true } );
    const firebase = getFirebase();

    userId = userId ? userId : firebase.auth().currentUser.uid;
    firebase.ref( `diaryNotes/${userId}` ).on( 'value', ( snapshot ) => {
      const data = snapshot.val();

      const diaryNotes = [];
      for ( const propertyKey in data ) {
        diaryNotes.push(
          {
            id: propertyKey,
            data: data[ propertyKey ]
          }
        );
      }
      dispatch( { type: FETCH_DIARY_NOTES, payload: diaryNotes } );
    } );
  }

}

const addDiaryNote = ( noteData, userId, userName, lng ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    userId = userId ? userId : firebase.auth().currentUser.uid;
    return firebase.ref( `diaryNotes/${userId}` ).push( noteData ).then( ( response ) => {
      firebase.ref( `visitors/${userId}` ).on( 'value', ( snapshot ) => {
        const visitors = snapshot.val(), diaryNotesLink = `${window.location.origin}/view-diary-notes?id=${userId}`;
        let visitorsEmails = "";
        for ( const visitor in visitors ) {
          visitorsEmails += `${visitors[ visitor ].email},` ;
        }
        return axios.get( `https://us-central1-visit-the-patient.cloudfunctions.net/newPost?&patientName=${userName}&dest=${visitorsEmails}&link=${diaryNotesLink}&lng=${lng}` )
        // return axios.get( `http://localhost:5001/visit-the-patient/us-central1/newPost?&patientName=${userName}&dest=${visitorsEmails}&link=${diaryNotesLink}&lng=${lng}` )
          .then( () => {
            const data = response === undefined ? '' : response;
            dispatch( { type: ADD_DIARY_NOTE, payload: data } )
          } )
      } )
    } ).catch( error => {
      dispatch( { type: `${ADD_DIARY_NOTE}_ERROR`, error } );
      return Promise.reject( error );
    } )
  }
}

const editDiaryNote = ( note, userId ) => {
  return ( dispatch, _getState, getFirebase ) => {
    const firebase = getFirebase();
    userId = userId ? userId : firebase.auth().currentUser.uid;
    return firebase.ref( `diaryNotes/${userId}/${note.id}` ).update( note.data ).then( ( response ) => {
      const data = response === undefined ? '' : response;
      dispatch( { type: EDIT_DIARY_NOTE, payload: data } )
    } ).catch( error => {
      dispatch( { type: `${EDIT_DIARY_NOTE}_ERROR`, error } );
      return Promise.reject( error );
    } )
  }
}


export { fetchDiaryNotes, addDiaryNote, editDiaryNote }
