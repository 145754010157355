import React from 'react';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CustomCard from '../../shared/CustomCard/CustomCard';
import styles from './TimeSlotsAgendaCards.module.scss';

const TimeSlotsAgendaCards = ( { history, timeSlots, visitors, t, i18n } ) => {


  const getAction = ( bookedById ) => {
    const bookedBy = visitors.length ? visitors.find( ( visitor ) => { return visitor.id === bookedById } ) : null;
    return bookedBy && (
      <div className={ styles.bookedBy }>
        <MoreHorizIcon></MoreHorizIcon>
        <span>{ t( 'booked by' ) }</span>
        <span>{ bookedBy.data.name }</span>
      </div>
    )
  }

  const editTimeSlot = ( timeSlot ) => {
    history.push( {
      pathname: `/agenda/edit`,
      search: `?id=${timeSlot.id}`,
      timeSlot: timeSlot,
    } )
  }

  const getPrettyDate = ( d ) => {
    const date = new Date( d );
    const languages = { en: "en-GB", ge: 'de-DE', de: 'nl-NL' }
    try {

      return new Intl.DateTimeFormat( languages[i18n.languages[0]], {
        weekday: 'long',
        month: "long",
        day: "2-digit"
      } ).format( date )
    }
    catch {
      return new Intl.DateTimeFormat( languages[i18n.languages[0]], { weekday: 'long', month: "long", day: "2-digit" } ).format( new Date() )
    }
  }

  const getCard = ( timeSlot ) => {
    const cardProps = {
      onClick: () => editTimeSlot( timeSlot ),
      key: timeSlot.id,
      title: getPrettyDate( timeSlot.data.date ),
      content: `${timeSlot.data.startTime} to ${timeSlot.data.endTime}`,
      action: getAction( timeSlot.data.bookedBy )
    };
    return ( <CustomCard { ...cardProps }></CustomCard> )
  }

  const getAppoimentsCards = () => {
    const timeSlotsCards = [];
    for ( const timeSlot in timeSlots ) {
      timeSlotsCards.push( getCard( timeSlots[ timeSlot ] ) )
    }
    return timeSlotsCards;
  }


  return (
    <div className={ styles.timeSlotsAgendaCards }>
      { getAppoimentsCards() }
    </div>
  )
};

export default withRouter( withNamespaces( "TimeSlotCards" )( TimeSlotsAgendaCards ) );
