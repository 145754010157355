import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import VTPImage from "../../assets/images/vtpLogo.png";
import { cancelAppointment } from '../../redux/actions/timeSlotsActions';
import LinkButton from '../shared/LinkButton/LinkButton';
import styles from './CancelAppointment.module.scss';

const CancelAppointment = ( { currentUser, visitors, location, cancelAppointment, t, i18n } ) => {
  const patientId = new URLSearchParams( location.search ).get( 'patientId' ),
    timeSlotId = new URLSearchParams( location.search ).get( 'timeSlotId' ),
    visitorId = new URLSearchParams( location.search ).get( 'visitorId' ),
    lng = new URLSearchParams( location.search ).get( 'lng' ),
    visitor = visitors.visitorsData.find( visitor => visitor.id === visitorId ),
    visitorName = visitor ? `${visitor.data.name ? visitor.data.name : ""}` : "",
    dest = currentUser ? currentUser.email : "";
  if ( dest && visitorName ) {
    cancelAppointment( patientId, timeSlotId, visitorName, dest, lng || i18n.languages[ 0 ].slice( 0, 2 ) );
  }
  return (
    <div className={ styles.CancelAppointment }>
      <img className={ styles.vtpImage } src={ VTPImage } alt=""></img>
      <h1>{ t( 'appointment cancelled' ) }</h1>
      <LinkButton to="/agenda" fullWidth>{ t( "go to main page" ) }</LinkButton>
    </div>
  )
}

const mapStateToProps = ( state ) => {
  return {
    currentUser: state.auth.currentUserData,
    visitors: state.visitors
  }
}

const confirmMailActions = ( dispatch ) => {
  return {
    cancelAppointment: ( patientId, timeSlotId, visitorName, patientEmail, lng ) => dispatch( cancelAppointment( patientId, timeSlotId, visitorName, patientEmail, lng ) )
  }
}

export default connect( mapStateToProps, confirmMailActions )( withRouter( withNamespaces( "CancelAppointment" )( CancelAppointment ) ) );
